import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { CancelButton, DeleteButton } from "../../../../mui/Buttons/Buttons";
import { Box, Stack, Typography } from "@mui/material";

export const Delete = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Delete Payment Invoice"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <DeleteButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Delete
                    </DeleteButton>
                }
                content={
                    <Box>
                        <Typography variant="h6">
                            Are you sure want to delete?
                        </Typography>
                    </Box>
                }
                actionButton={
                    <>
                        <CancelButton>Cancel</CancelButton>
                        <DeleteButton>Delete</DeleteButton>
                    </>
                }
            />
        </div>
    );
};
