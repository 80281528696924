import { Button } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const LeaveApprovalOperations = ({ params }) => {
    const [disable, setDisable] = useState(false);
    const [hr, setHr] = useState(false);

    const { pathname } = useLocation();
    const page = pathname.split("/");

    useEffect(() => {
        if (page.pop() === "all-leave-applications") {
            setHr(true);
            if (
                (Boolean(params.row.hrLastUpdate) &&
                    Boolean(params.row.hodLastUpdate)) ||
                !Boolean(params.row.hodLastUpdate)
            ) {
                setDisable(true);
            }
        } else {
            if (params.row.hodLastUpdate) {
                setDisable(true);
            }
        }
    }, [params.row]);

    return (
        <Button
            LinkComponent={Link}
            to={
                hr
                    ? `/my-tasks/hr-leave-approve/${params.row.leaveId}`
                    : `/my-tasks/reporting_head_or_hod/hod-leave-approve/${params.row.leaveId}`
            }
            variant="contained"
            size="small"
            color="warning"
            disabled={disable}
        >
            Approve
        </Button>
    );
};

export default LeaveApprovalOperations;
