import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import DashboardWidgetSummaryCard from "../../../../components/Dashboard/DashboardWidgetSummaryCard/DashboardWidgetSummaryCard";
import { Box, Grid } from "@mui/material";
import Chart from "../../../../components/DashBoardCharts/ReactApexCharts/Chart";
import PieChart from "../../../../components/DashBoardCharts/ReactApexCharts/PieChart";
import { BarChart } from "../../../../components/DashBoardCharts/ReactApexCharts/BarChart";
import { RadarChart } from "../../../../components/DashBoardCharts/ReactApexCharts/RadarChart";

export const DashBoard = () => {
    return (
        <div>
            <Wrapper
                title="Dashboard"
                Content={
                    <>
                        <Box>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCard
                                        title="Weekly Sales"
                                        total={714000}
                                        icon={"ant-design:android-filled"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCard
                                        title="New Users"
                                        total={1352831}
                                        color="info"
                                        icon={"ant-design:apple-filled"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCard
                                        title="Item Orders"
                                        total={1723315}
                                        color="warning"
                                        icon={"ant-design:windows-filled"}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6} md={3}>
                                    <DashboardWidgetSummaryCard
                                        title="Bug Reports"
                                        total={234}
                                        color="error"
                                        icon={"ant-design:bug-filled"}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={8}>
                                    <Chart />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <PieChart />
                                </Grid>
                                <Grid item xs={12} md={6} lg={8}>
                                    <BarChart />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4}>
                                    <RadarChart />
                                </Grid>
                            </Grid>
                        </Box>
                    </>
                }
            />
        </div>
    );
};
