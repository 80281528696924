import React, { useState } from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { CancelButton, RejectButton } from "../../../mui/Buttons/Buttons";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box, Stack, TextField } from "@mui/material";
import { useFormik, FormikProvider, Form } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
    getMentorRequest,
    rejectRequest,
} from "../../../../redux/features/mentoringSlice";
import { useSnackbar } from "notistack";

export const RejectRequest = ({ params }) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [value, setValue] = React.useState(dayjs());
    const [fromTime, setFromTime] = React.useState(dayjs("12:00:00"));
    const [toTime, setToTime] = React.useState(dayjs("12:00:00"));
    const { loading } = useSelector((state) => state.mentoring);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: params.mentoring_bookings_id,
            mentor_rejectin_remark: "",
            mentor_suggested_date: dayjs(value).format("DD/MM/YYYY") || "",
            mentor_suggested_fromTime: dayjs(fromTime).format("HH:mm:00") || "",
            mentor_suggested_toTime: dayjs(toTime).format("HH:mm:00") || "",
        },
        onSubmit: (values) => {
            dispatch(rejectRequest(values)).then((res) => {
                if (res.payload.status === "failed") {
                    enqueueSnackbar(res.payload.message, { variant: "error" });
                } else if (res.payload.status === "success") {
                    setOpen(false);
                    dispatch(getMentorRequest());
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                }
            });
        },
    });
    return (
        <div>
            <GlobalDialog
                title="Reject Request"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                closeButton={true}
                popupButton={
                    <>
                        {params.status === 0 ? (
                            <RejectButton
                                size="small"
                                action={() => setOpen(true)}
                            >
                                Reject
                            </RejectButton>
                        ) : (
                            ""
                        )}
                    </>
                }
                content={
                    <>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Box>
                                    <Box>
                                        <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                        >
                                            <DemoContainer
                                                components={["DatePicker"]}
                                            >
                                                <DatePicker
                                                    name=" mentor_suggested_date"
                                                    sx={{ width: "100%" }}
                                                    label="Date"
                                                    value={value}
                                                    disablePast
                                                    onChange={(newValue) => {
                                                        formik.setFieldValue(
                                                            " mentor_suggested_date",
                                                            dayjs(
                                                                newValue
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )
                                                        );
                                                        setValue(newValue);
                                                    }}
                                                />
                                            </DemoContainer>
                                        </LocalizationProvider>
                                    </Box>
                                    {/* ----------------------------------------------------- */}
                                    <Stack direction={"row"} spacing={2} pt={2}>
                                        <Box width={"100%"}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DemoContainer
                                                    components={["TimePicker"]}
                                                >
                                                    <TimePicker
                                                        timeSteps={30}
                                                        name="mentor_suggested_fromTime"
                                                        sx={{ width: "100%" }}
                                                        label="From Time"
                                                        value={fromTime}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            formik.setFieldValue(
                                                                "mentor_suggested_fromTime",
                                                                dayjs(
                                                                    newValue
                                                                ).format(
                                                                    "HH:mm:00"
                                                                )
                                                            );
                                                            setFromTime(
                                                                newValue
                                                            );
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Box>
                                        <Box width={"100%"}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDayjs}
                                            >
                                                <DemoContainer
                                                    components={["TimePicker"]}
                                                >
                                                    <TimePicker
                                                        timeSteps={30}
                                                        name="mentor_suggested_toTime"
                                                        sx={{ width: "100%" }}
                                                        label="To Time"
                                                        value={toTime}
                                                        onChange={(
                                                            newValue
                                                        ) => {
                                                            formik.setFieldValue(
                                                                "mentor_suggested_toTime",
                                                                dayjs(
                                                                    newValue
                                                                ).format(
                                                                    "HH:mm:00"
                                                                )
                                                            );
                                                            setToTime(newValue);
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </Box>
                                    </Stack>
                                    <Box pt={2}>
                                        <TextField
                                            name="mentor_rejectin_remark"
                                            label="Remarks"
                                            multiline
                                            rows={3}
                                            fullWidth
                                            required
                                            value={
                                                formik.values
                                                    .mentor_rejectin_remark
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            pt: 2,
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={2}>
                                            <RejectButton type="submit">
                                                {loading
                                                    ? "Rejecting..."
                                                    : "Reject"}
                                            </RejectButton>
                                        </Stack>
                                    </Box>
                                </Box>
                            </Form>
                        </FormikProvider>
                    </>
                }
            />
        </div>
    );
};
