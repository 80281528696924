import React from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box } from "@mui/material";
import CustomDataGrid from "../../../../components/mui/DataGrid/CustomDataGrid";
import { PAYMENT_INVOICES_TABLE_HEAD as columns } from "../../../../components/datagrid/myworkspace/cro/PaymentInvoices/PaymentInvoicesTableHead";
import { Actions } from "./Actions";
import { AddPaymentInvoice } from "../../../../components/datagrid/myworkspace/cro/PaymentInvoices/AddPaymentInvoice";
import { Invoice } from "./Invoice";

const data = [
    {
        id: 1,
        name: "AMEEN NASIH (5808)",
        paymentAmount: 6000,
        admissionFee: 2000,
        invoiceGeneratedOn: "2022-12-15 11:58:59",

        paymentLink: "",
        paymentStatus: "Not Paid	",

        actions: "",
        transactionTimeStamp: "2022-12-26 12:26:04",
        settlementTimeStamp: "2022-12-26 12:26:04",
    },
];

const Head = [
    {
        field: "name",
        headerName: "Student Name",
        width: 250,
    },
];

export default function PaymentInvoices() {
    const [selectedRows, setSelectedRows] = React.useState([]);
    return (
        <div>
            <Wrapper
                title="Payment Invoices"
                Content={
                    <>
                        <Box
                            sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                            <AddPaymentInvoice />
                        </Box>
                        <Box>
                            <CustomDataGrid
                                data={data}
                                rowId={"id"}
                                columns={columns}
                                Head={Head}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                                chipValue={selectedRows[0]?.paymentAmount}
                                label="payment Amount"
                                name={selectedRows[0]?.name}
                                // details={<Details data={selectedRows[0]} />}
                                actions={<Actions />}
                            />
                        </Box>
                    </>
                }
            />
        </div>
    );
}
