import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  deleteLeave,
  getLeaveAplictions,
} from "../../../redux/features/leaveSlice";
import {
  CancelButton,
  DeleteButton,
  EditButton,
} from "../../mui/Buttons/Buttons";

const LeaveOpereations = ({ params }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const handleDelete = (id) => {
    dispatch(deleteLeave(id)).then((res) => {
      if (res.payload.status === "error") {
        enqueueSnackbar(res.payload.message, {
          variant: "error",
        });
      } else if (res.payload.status === "success") {
        enqueueSnackbar(res.payload.message, {
          variant: "success",
        });
        dispatch(getLeaveAplictions(new Date().getFullYear()));
      }
    });
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Stack direction="row" gap={2}>
        <EditButton
          size="small"
          component={Link}
          disabled={params.row.isEditable !== 1}
          to={`/employeeservices/edit-leave-application/${params.row.leaveId}`}
        >
          Edit
        </EditButton>

        <DeleteButton
          action={() => setOpen(true)}
          disabled={params.row.isEditable !== 1}
        >
          Delete
        </DeleteButton>
      </Stack>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Paper sx={{ bgcolor: "grey.200" }}>
          <DialogTitle id="alert-dialog-title">{"Alert"}</DialogTitle>
        </Paper>
        <Divider />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are sure want to delete this Leave application?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <CancelButton action={handleClose}>Cancel</CancelButton>
          <DeleteButton action={() => handleDelete(params.row.leaveId)}>
            Delete
          </DeleteButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default LeaveOpereations;
