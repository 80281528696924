import React, { useCallback, useEffect } from "react";
import PopUps from "./PopUps";
import PopUpDataGrid from "./PopUpDataGrid";
import { Box, Button } from "@mui/material";
import TooltipRemarkViewer from "./TooltipRemarkViewer";
import { useDispatch, useSelector } from "react-redux";
import { getDetailedOgaRemark } from "../../../../redux/features/croSlice";
import { debounce } from "lodash";
import { Icon } from "@iconify/react";

const OgaRemarksDetailedView = ({ row }) => {
    const [open, setOpen] = React.useState(false);
    const dispatch = useDispatch();

    const componentProps = {
        name: row.studentName,
        contactPerson: row.contactPerson,
        phone: row.contactNumber,
        place: row.place,
        sro: row.oga,
    };

    const handleClickOpen = () => {
        setOpen(true);
        dispatch(getDetailedOgaRemark(row.croAssignmentId));
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>
                <Icon icon="clarity:info-solid" width="40" height="40" />
            </Button>
            <PopUps
                component={PopUpDataGrid}
                setOpen={setOpen}
                open={open}
                componentProps={componentProps}
            />
        </div>
    );
};

export default OgaRemarksDetailedView;
