import React from "react";
import { styled } from "@mui/material/styles";
import { FormControlLabel, Switch } from "@mui/material";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
export const StatusToggleSwitch = ({ api, initialValue }) => {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [value, setValue] = useState(Boolean(initialValue));
    const IOSSwitch = styled((props) => (
        <Switch
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
            {...props}
        />
    ))(({ theme }) => ({
        width: 41,
        height: 20,
        padding: 0,
        "& .MuiSwitch-switchBase": {
            padding: 0,
            margin: 2,
            transitionDuration: "300ms",
            "&.Mui-checked": {
                transform: "translateX(16px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    backgroundColor:
                        theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
                    opacity: 1,
                    border: 0,
                },
                "&.Mui-disabled + .MuiSwitch-track": {
                    opacity: 0.5,
                },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
                color: "#33cf4d",
                border: "6px solid #fff",
            },
            "&.Mui-disabled .MuiSwitch-thumb": {
                color:
                    theme.palette.mode === "light"
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
            },
        },
        "& .MuiSwitch-thumb": {
            boxSizing: "border-box",
            width: 19,
            height: 16,
        },
        "& .MuiSwitch-track": {
            borderRadius: 26 / 2,
            backgroundColor:
                theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
            opacity: 1,
            transition: theme.transitions.create(["background-color"], {
                duration: 500,
            }),
        },
    }));

    return (
        <div>
            <FormControlLabel
                control={
                    <IOSSwitch
                        sx={{ m: 1 }}
                        checked={value}
                        onChange={(e) => {
                            setValue(Boolean(e.target.checked));
                            dispatch(api).then((res) => {
                                if (res.payload.status === "failed") {
                                    enqueueSnackbar(res.payload.message, {
                                        variant: "error",
                                    });
                                } else if (res.payload.status === "success") {
                                    enqueueSnackbar(res.payload.message, {
                                        variant: "success",
                                    });
                                }
                            });
                        }}
                    />
                }
            />
        </div>
    );
};
