import React from "react";
import GlobalDeleteDialog from "../../../../components/mui/Dialogue/GlobalDeleteDialog";
import {
    deleteBooking,
    getAssetTimeSlotsForADay,
} from "../../../../redux/features/cabinBookingSlice";
import { useSelector } from "react-redux";

export const DeleteBookings = ({ bookingDetailsById, bookingId, setOpen }) => {
    const { loading } = useSelector((state) => state.cabin);
    const data = {
        id: bookingDetailsById?.cabin_id,
        date: bookingDetailsById?.booking_date,
    };
    return (
        <div>
            <GlobalDeleteDialog
                loading={loading}
                title="Delete Booking"
                deleteNote="Are you sure .You want to delete this booking..?"
                api={deleteBooking(bookingId)}
                upDateCall={getAssetTimeSlotsForADay(data)}
                otherActions={setOpen}
            />
        </div>
    );
};
