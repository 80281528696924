export const INCOME_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "year",
        headerName: "year",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Month",
        headerName: "Month",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Fee",
        headerName: "Fee Type",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Student",
        headerName: "Student Name",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Course",
        headerName: "Course",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Transaction",
        headerName: "Transaction Date",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Amount",
        headerName: "Amount",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Credited",
        headerName: "Credited A/C",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Screen",
        headerName: "Screen Shot",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Remarks",
        headerName: "Remarks",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Time",
        headerName: "Time Stamp",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "User",
        headerName: "User",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Verification",
        headerName: "Verification",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Action",
        headerName: "Action",
        headerAlign: "left",
        align: "left",
    },
];
