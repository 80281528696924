import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    croLeads: [],
    ogaRemarks: [],
    courseEnrollments: [],
    singleStudent: {},
    subjects: [],
    subjectEnrolled: [],
    leadStatuses: [],
    expectedSessions: [],
    totalExpectedSessions: null,
    filterDate: {
        year: "",
        month: "",
    },
    courses: [],
    students: [],
    subjectSessionCounts: [],
    admissionRegFilter: {
        croId: "",
        courseId: "",
        courseStatusId: "",
    },
    subjectSessionCompleated: [],
    studentProfile: {},
    countries: [],
    student: {},
    classes: [],
    syllabuses: [],
    mediums: [],
    pendingEnquiries: [],
    CroEnquiryLog: [],
    croStudentEnquiryLog: [],
    studentFeeStructure: [],
    OgaList: [],
    mobileAppStudents: [],
    lgStudentDetails: {},
    studentMonthlyLedger: [],
    ledgerAction: "",
    studentMonthlyLedgerSummary: {
        accountsEntityId: "",
        monthAndYear: "",
        student: "",
        openingBalance: "",
        totalIncome: "",
        totalExpense: "",
        closingBalance: "",
        nextMonthAndYear: "",
        nextOpeningBalance: "",
        remarks: "",
    },
    croStudentsNotInApp: [],
    croStudentsEmailIds: [],
    nextLedger: {},
    previousLedger: {},
    refAdmissionRegister: [],
    croEnquiriesFoundation: [],
    croEnquiriesClassroom: [],
    dayWiseSessions: [],
    sessionCancellationReasons: [],
    projects: [],
    projectClasses: [],
    projectsPreData: [],
    trainerAttandace: [],
    attendanceTypes: [],
    subjectTopicsPreData: [],
    studentsForEnrollmentDetailed: [],
    invoicesByCourseEnrollmentPreData: [],
    tpVerificationData: [],
    tpVerificationDataDetailed: [],
    detailedSubjectEnrollementsByStudent: [],
    levelTypePreData: [],
    subjectsByClassPreData: [],
    projectCategoriesPreData: [],
    detailedSubjectEnrollementsByCourse: [],
    courseEnrollmentDtldId: {},
    detailedCourseEnrollementsByStudent: [],
    detailedCourseEnrollementsByCourse: [],
    classRestartDates: [],
    croStudents: [],
    navigatedDate: [],
    allAttendensPerStudent: [],
};

export const getCroLeads = createAsyncThunk("cro/get_leads", async () => {
    const res = await apiRequest({
        url: "lead/getCroLeads",
        method: "get",
    });

    return res.data;
});

export const getDetailedOgaRemark = createAsyncThunk(
    "cro/get_oga_remarks",
    async (id) => {
        const res = await apiRequest({
            url: `lead/getDetailedOgaRemarks/${id}`,
            method: "get",
        });
        return res.data;
    }
);

export const UpdateCroLeads = createAsyncThunk(
    "cro/update_lead",
    async ({ id, data }) => {
        const res = await apiRequest({
            url: `lead/updateCroLead/${id}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const getCourseEnrollments = createAsyncThunk(
    "cro/get_course_enrollments",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "students/getCourseEnrollments",
        });
        return res.data;
    }
);

export const getCroLeadStatuses = createAsyncThunk(
    "cro/get_lead_sts",
    async () => {
        const res = await apiRequest({
            url: "pre-data/getCroLeadStatuses",
            method: "get",
        });
        return res.data;
    }
);

export const getStudentCourseEnrollments = createAsyncThunk(
    "cro/get_stdnt_cErlmnts",
    async (id) => {
        const res = await apiRequest({
            url: `students/getCourseEnrollment/${id}`,
            method: "get",
        });
        return res.data;
    }
);

export const getSubjects = createAsyncThunk("cro/get_subject", async () => {
    const res = await apiRequest({
        url: "pre-data/getSubjects",
        method: "get",
    });
    return res.data;
});

export const croEnrollSubjects = createAsyncThunk(
    "cro/cro_update_subject",
    async (values) => {
        const res = await apiRequest({
            url: "students/enrollSubjects",
            method: "post",
            data: values,
        });
        return res;
    }
);

export const getExpectedSessions = createAsyncThunk(
    "cro/get_expected_sessions",
    async ({ date, croId }) => {
        const res = await apiRequest({
            url: `students/getExpectedSessions/${date}/${croId}`,
            method: "get",
        });
        return res.data;
    }
);

export const addExpectedSessions = createAsyncThunk(
    "cro/add_expected_session",
    async (data) => {
        const res = await apiRequest({
            url: "students/addExpectedSessions",
            method: "post",
            data,
        });
        return res;
    }
);

export const getCourses = createAsyncThunk("cro/get_courses", async () => {
    const res = await apiRequest({
        url: "pre-data/getCourses",
        method: "get",
    });
    return res.data;
});

export const getAdmissionRegister = createAsyncThunk(
    "cro/admsn_rgstr",
    async (query) => {
        const res = await apiRequest({
            url: `students/getAdmissionRegister/${query.croId}/${query.courseId}/${query.courseStatusId}`,
            method: "get",
        });
        return res.data;
    }
);

export const getSubjectSessionCount = createAsyncThunk(
    "cro/get_sbjt_sessn_cunt",
    async (courseEId) => {
        const res = await apiRequest({
            url: `students/getSubjectSessionCount/${courseEId}`,
            method: "get",
        });

        return res.data;
    }
);

export const updateSubjectSessionCount = createAsyncThunk(
    "cro/updt_sessn_cout",
    async ({ sEId, sessionCount }) => {
        const res = await apiRequest({
            url: `students/updateSubjectSessionCount/${sEId}`,
            method: "put",
            data: {
                sessionCount,
            },
        });

        return res;
    }
);

export const deleteSubjectEnrollments = createAsyncThunk(
    "cro/DLT_SUB_ENROLLMENTS",
    async (subEId) => {
        const res = await apiRequest({
            url: `students/deleteSubjectEnrollment/${subEId}`,
            method: "delete",
        });

        return res;
    }
);

export const getSubjectsSessionsCompleated = createAsyncThunk(
    "cro/get_subjct_sesn_cmpltd",
    async (cEId) => {
        const res = await apiRequest({
            url: `students/getSubjectSessionsCompleted/${cEId}`,
            method: "get",
        });

        return res.data;
    }
);

export const getStudentProfile = createAsyncThunk(
    "cro/get_student_profile",
    async (cEId) => {
        const res = await apiRequest({
            url: `students/getStudentProfile/${cEId}`,
            method: "get",
        });

        return res.data;
    }
);

export const getCountries = createAsyncThunk("cro/get_countries", async () => {
    const res = await apiRequest({
        url: "pre-data/getCountries",
        method: "get",
    });

    let countries = [];
    res.data.countries.forEach((item) => {
        item = {
            ...item,
            displayName: `${item.countryName}(${item.phonecode})`,
        };
        countries.push(item);
    });

    return countries;
});

export const getStudent = createAsyncThunk("cro/get_student", async (admNo) => {
    const res = await apiRequest({
        url: `students/getStudent/${admNo}`,
        method: "get",
    });

    return res.data;
});

export const getClasses = createAsyncThunk("cro/get_classes", async () => {
    const res = await apiRequest({
        url: "pre-data/getClasses",
        method: "get",
    });

    return res.data;
});

export const getSyllabus = createAsyncThunk("cro/get_syllabus", async () => {
    const res = await apiRequest({
        url: "pre-data/getSyllabuses",
        method: "get",
    });

    return res.data;
});

export const getMediums = createAsyncThunk("cro/get_mediums", async () => {
    const res = await apiRequest({
        url: "pre-data/getMediums",
        method: "get",
    });
    return res.data;
});

export const updateStudent = createAsyncThunk(
    "cro/updt_student",
    async ({ data, admNo }) => {
        const res = await apiRequest({
            url: `students/updateStudent/${admNo}`,
            method: "put",
            data: data,
        });

        return res;
    }
);
export const getCroPendingEnquiries = createAsyncThunk(
    "cro/getCroPendingEnquiries",
    async (courseType) => {
        const res = await apiRequest({
            url: `cr/getCroPendingEnquiries/${courseType}`,
            method: "get",
        });

        return res;
    }
);

export const getFoundationPendingEnquiries = createAsyncThunk(
    "cro/get_pending_foundation",
    async (croId) => {
        const res = await apiRequest({
            url: `cr/getCroPendingEnquiriesFoundation/${croId}`,
            method: "get",
        });

        return res.data;
    }
);

export const getClassroomPendingEnquiries = createAsyncThunk(
    "cro/get_pending_classroom",
    async (croId) => {
        const res = await apiRequest({
            url: `cr/getCroPendingEnquiriesClassroom/${croId}`,
            method: "get",
        });

        return res.data;
    }
);

export const getCroEnquiryLog = createAsyncThunk(
    "cro/getCroEnquiryLog",
    async (data) => {
        const res = await apiRequest({
            url: `cr/getCroEnquiryLog/${data.CourseTypeId}/${data.date}`,
            method: "get",
        });

        return res;
    }
);

export const getCroStudentEnquiryLog = createAsyncThunk(
    "cro/getCroStudentEnquiryLog",
    async (data) => {
        const res = await apiRequest({
            url: `cr/getCroStudentEnquiryLog/${data.courseType}/${data.subjectEnrollmentId}`,
            method: "get",
        });

        return res;
    }
);

export const getStudentFeeStructure = createAsyncThunk(
    "cro/getStdntFeeStructure",
    async (cEid) => {
        const res = await apiRequest({
            url: `cr/getStudentFeeStructure/${cEid}`,
            method: "get",
        });
        return res.data;
    }
);

export const getOgaList = createAsyncThunk("cro/get_oga_list", async () => {
    const res = await apiRequest({
        url: "pre-data/getOGAs",
        method: "get",
    });
    return res.data;
});

export const addOgaRetainSchedule = createAsyncThunk(
    "cro/add_oga_schedule",
    async (details) => {
        const res = await apiRequest({
            url: "cr/addOGARetainSchedule",
            method: "post",
            data: details,
        });
        return res;
    }
);

export const getMobileAppStudents = createAsyncThunk(
    "cro/getMobileAppStudents",
    async (userId) => {
        const res = await apiRequest({
            url: `cr/getMobileAppStudentList/${userId}`,
            method: "get",
        });

        return res;
    }
);

export const addStudentToMobileApp = createAsyncThunk(
    "cro/addStudentToMobileApp",
    async (data) => {
        const res = await apiRequest({
            url: `cr/addStudentsToMobileApp`,
            method: "post",
            data: data,
        });

        return res;
    }
);
export const updateMobileAppStudent = createAsyncThunk(
    "cro/updateMobileAppStudent",
    async (data) => {
        const res = await apiRequest({
            url: `cr/updateMobileAppStudent/${data.admnNo}`,
            method: "put",
            data: data,
        });

        return res;
    }
);

export const deleteMobileAppStudent = createAsyncThunk(
    "cro/deleteMobileAppStudent",
    async (adno) => {
        const res = await apiRequest({
            url: `cr/deleteMobileAppStudent/${adno}`,
            method: "delete",
        });

        return res;
    }
);

export const getStudentMonthlyLedger = createAsyncThunk(
    "cro/stdnt_mnth_ledger",
    async (param) => {
        const res = await apiRequest({
            url: `cr/getStudentMonthlyLedger/${param}`,
            method: "get",
        });

        return res.data;
    }
);

export const closeAccounts = createAsyncThunk(
    "cro/close_accounts",
    async (data) => {
        const res = await apiRequest({
            url: `cr/closeStudentMonthlyLedger/${data.accountsEntityId}`,
            method: "put",
            data: data.body,
        });

        return res;
    }
);

export const getAccountsSummery = createAsyncThunk(
    "cro/get_summery",
    async (params) => {
        const res = await apiRequest({
            url: `cr/getStudentMonthlyLedgerSummary/${params}`,
            method: "get",
        });

        return res.data;
    }
);
export const getCROStudentsNotInApp = createAsyncThunk(
    "cro/getCROStudentsNotInApp",
    async () => {
        const res = await apiRequest({
            url: `cr/getCROStudentsNotInApp`,
            method: "get",
        });

        return res;
    }
);

export const getCROStudentsEmailIds = createAsyncThunk(
    "cro/getCROStudentsEmailIds",
    async () => {
        const res = await apiRequest({
            url: `cr/getCROStudentsEmailIds`,
            method: "get",
        });

        return res;
    }
);

//<----------------referralAdmissionregiter related calls-------------------->

export const getReferralAdmissionRegister = createAsyncThunk(
    "cro/get_referral_admsn_reg",
    async (userId) => {
        const res = await apiRequest({
            url: `cr/getReferralAdmissionRegister/${userId}`,
            method: "get",
        });

        return res.data;
    }
);
export const updateTerm1Fee = createAsyncThunk(
    "cro/update_term1_fee",
    async (params) => {
        const res = await apiRequest({
            url: `cr/updateStudentTerm1Fee/${params.courseEnrollmentId}`,
            method: "put",
            data: {
                term1Fee: params.term1Fee,
            },
        });

        return res;
    }
);

export const getDayWiseSessions = createAsyncThunk(
    "cro/getDayWiseSessions",
    async (data) => {
        const res = await apiRequest({
            url: `cr/getDailySessions/-1/${data.date}`,
            // url: `cr/getDailySessions/-1/2023-08-21`,
            method: "get",
        });

        return res;
    }
);
export const cancelSession = createAsyncThunk(
    "cro/cancelSession",
    async (data) => {
        const res = await apiRequest({
            url: `cr/cancelSessionSchedule/${data.scheduleId}`,
            method: "put",
            data: data,
        });

        return res;
    }
);
export const getSessionCancellationReasons = createAsyncThunk(
    "cro/getSessionCancellationReasons",
    async () => {
        const res = await apiRequest({
            url: `general/getSessionCancellationReasons`,
            method: "get",
        });

        return res;
    }
);

export const reScheduleSession = createAsyncThunk(
    "cro/reScheduleSession",
    async (data) => {
        const res = await apiRequest({
            url: `cr/rescheduleSessionSchedule/${data.scheduleId}`,
            method: "post",
            data: data,
        });

        return res;
    }
);

export const getTrainerAttendance = createAsyncThunk(
    "cro/gettrainerAttendance",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            url: `cr/getStudentSubjectSessions/${courseEnrollmentId}`,
            method: "get",
        });

        return res;
    }
);
export const getAttendanceTypes = createAsyncThunk(
    "cro/getAttendanceTypes",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getAttendanceTypes`,
            method: "get",
        });

        return res;
    }
);

export const getSubjectTopics = createAsyncThunk(
    "cro/getSubjectTopics",
    async (scheduleId) => {
        const res = await apiRequest({
            url: `cr/getSubjectTopics/${scheduleId}`,
            method: "get",
        });

        return res;
    }
);
export const addTrainerAttendance = createAsyncThunk(
    "cro/addTrainerAttendance",
    async (data) => {
        const res = await apiRequest({
            url: `cr/addTrainerAttendance`,
            method: "post",
            data: data,
        });

        return res;
    }
);
export const deleteTrainerAttendance = createAsyncThunk(
    "cro/deleteTrainerAttendance",
    async (scheduleId) => {
        const res = await apiRequest({
            url: `cr/deleteTrainerAttendance/${scheduleId}`,
            method: "delete",
        });

        return res;
    }
);

export const getStudentsForEnrollmentDetailed = createAsyncThunk(
    "cro/getStudentsForEnrollmentDetailed",
    async (croId) => {
        const res = await apiRequest({
            url: `cr/getStudentsForEnrollmentDetailed/${croId}`,
            method: "get",
        });

        return res;
    }
);

export const getInvoicesByCourseEnrollment = createAsyncThunk(
    "cro/getInvoicesByCourseEnrollment",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            url: `cr-pre-data/getInvoicesByCourseEnrollment/${courseEnrollmentId}`,
            method: "get",
        });

        return res;
    }
);

export const addCourseEnrollmentDetailed = createAsyncThunk(
    "cro/addCourseEnrollmentDetailed",
    async (data) => {
        const res = await apiRequest({
            url: `cr/addCourseEnrollmentDetailed`,
            method: "post",
            data: data,
        });

        return res;
    }
);
export const getTpVerificationData = createAsyncThunk(
    "cro/getTpVerificationData",
    async (data) => {
        const res = await apiRequest({
            url: `cr/getTpVerificationData/${data.croId}/${data.date}`,
            method: "get",
        });

        return res;
    }
);
export const getTpVerificationDataDetailed = createAsyncThunk(
    "cro/getTpVerificationDataDetailed",
    async (tpInvoiceId) => {
        const res = await apiRequest({
            url: `cr/getTpVerificationDataDetailed/${tpInvoiceId}`,
            method: "get",
        });

        return res;
    }
);

export const updateTpVerificationData = createAsyncThunk(
    "cro/updateTpVerificationData",
    async (data) => {
        const res = await apiRequest({
            url: `cr/updateTpVerificationData/${data.tpInvoiceId}`,
            method: "put",
            data: data,
        });

        return res;
    }
);

export const getDetailedSubjectEnrollementsByStudent = createAsyncThunk(
    "cro/getDetailedSubjectEnrollementsByStudent",
    async (admissionNumber) => {
        const res = await apiRequest({
            url: `cr/getDetailedSubjectEnrollmentsByStudent/${admissionNumber}`,
            method: "get",
        });

        return res;
    }
);
export const addSubjectEnrollmentDetailed = createAsyncThunk(
    "cro/addSubjectEnrollmentDetailed",
    async (data) => {
        const res = await apiRequest({
            url: `cr/addSubjectEnrollmentDetailed`,
            method: "post",
            data: data,
        });

        return res;
    }
);

export const getLevelType = createAsyncThunk("cro/getLevelType", async () => {
    const res = await apiRequest({
        url: `cr-pre-data/getLevelType`,
        method: "get",
    });

    return res;
});

export const getSubjectsByClass = createAsyncThunk(
    "cro/getSubjectsByClass",
    async (classId) => {
        const res = await apiRequest({
            url: `cr-pre-data/getSubjects/${classId}`,
            method: "get",
        });

        return res;
    }
);
export const getProjectCategories = createAsyncThunk(
    "cro/getProjectCategories",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getProjCategories`,
            method: "get",
        });
        return res;
    }
);

export const getDetailedSubjectEnrollementsByCourse = createAsyncThunk(
    "cro/getDetailedSubjectEnrollementsByCourse",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            url: `cr/getDetailedSubjectEnrollmentsByCourse/${courseEnrollmentId}`,
            method: "get",
        });
        return res;
    }
);
export const getDetailedCourseEnrollementsByStudent = createAsyncThunk(
    "cro/getDetailedCourseEnrollementsByStudent",
    async (admissionNumber) => {
        const res = await apiRequest({
            url: `cr/getDetailedCourseEnrollmentsByStudent/${admissionNumber}`,
            method: "get",
        });

        return res;
    }
);

export const getDetailedCourseEnrollementsByCourse = createAsyncThunk(
    "cro/getDetailedCourseEnrollementsByCourse",
    async (courseEnrollmentId) => {
        const res = await apiRequest({
            url: `cr/getDetailedCourseEnrollmentsByCourse/${courseEnrollmentId}`,
            method: "get",
        });
        return res;
    }
);
export const updateCourseEnrollmentDetailed = createAsyncThunk(
    "cro/updateCourseEnrollmentDetailed",
    async (data) => {
        const res = await apiRequest({
            url: `cr/updateCourseEnrollmentDetailed/${data.courseEnrollmentDetailedId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);
export const getAllStudentClassRestartedDates = createAsyncThunk(
    "cro/getAllStudentClassRestartedDates",
    async () => {
        const res = await apiRequest({
            url: `cr/getAllStudentClassRestartedDates`,
            method: "get",
        });
        return res;
    }
);

export const getCroStudents = createAsyncThunk(
    "cro/getCroStudents",
    async () => {
        const res = await apiRequest({
            url: `students/cro-students`,
            method: "get",
        });
        return res;
    }
);
export const getAllAttendensPerStudent = createAsyncThunk(
    "cro/getAllAttendensPerStudent",
    async (data) => {
        console.log("preedata-=-", data);
        const res = await apiRequest({
            url: `students/getAllAttendensPerStudent/${data.selectedAdmissionNumber}`,
            // url: `students/getAllAttendensPerStudent/6833`,
            method: "post",
            data: data,
        });
        return res;
    }
);
const croSlice = createSlice({
    name: "cro",
    initialState: initialState,
    reducers: {
        getEnrolledSubjects: (state, action) => {
            const enrolled = action.payload.map((item) => item.subjectId);

            return {
                ...state,
                subjectEnrolled: enrolled,
            };
        },
        storeDate: (state, action) => {
            let [year, month] = action.payload.split("/");
            return {
                ...state,
                filterDate: {
                    year,
                    month,
                },
            };
        },
        saveAdmissionRegFilter: (state, action) => {
            return {
                ...state,
                admissionRegFilter: action.payload,
            };
        },
        clearStudent: (state, action) => {
            return {
                ...state,
                student: {},
            };
        },

        clearStudentFeeStructure: (state, action) => {
            return {
                ...state,
                studentFeeStructure: [],
            };
        },
        navigatedDate: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                navigatedDate: action.payload,
            };
        },
        clearStudentAttendance: (state, action) => {
            return {
                ...state,
                allAttendensPerStudent: [],
            };
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCroLeads.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroLeads.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(action.payload.croLeads);
            return {
                ...state,
                croLeads: formated,
                loading: false,
            };
        });
        builder.addCase(getDetailedOgaRemark.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getDetailedOgaRemark.fulfilled, (state, action) => {
            return {
                ...state,
                ogaRemarks: [{ id: "unique", ...action.payload.remarks }],
                loading: false,
            };
        });
        builder.addCase(getCourseEnrollments.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCourseEnrollments.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(
                action.payload.courseEnrollments
            );

            return {
                ...state,
                courseEnrollments: formated,
                loading: false,
            };
        });
        builder.addCase(
            getStudentCourseEnrollments.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getStudentCourseEnrollments.fulfilled,
            (state, action) => {
                return {
                    ...state,
                    singleStudent: action.payload.courseEnrollment,
                    loading: false,
                };
            }
        );
        builder.addCase(getSubjects.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjects.fulfilled, (state, action) => {
            return {
                ...state,
                subjects: action.payload.subjects,
                loading: false,
            };
        });
        builder.addCase(croEnrollSubjects.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(croEnrollSubjects.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(getCroLeadStatuses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroLeadStatuses.fulfilled, (state, action) => {
            return {
                ...state,
                leadStatuses: action.payload.croLeadStatuses,
                loading: false,
            };
        });
        builder.addCase(getExpectedSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getExpectedSessions.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(
                action.payload.expectedSessions
            );
            return {
                ...state,
                expectedSessions: formated,
                totalExpectedSessions: action.payload.totalExpectedSessions,
                loading: false,
            };
        });
        builder.addCase(getCourses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCourses.fulfilled, (state, action) => {
            return {
                ...state,
                courses: [
                    { courseId: -1, courseName: "All" },
                    ...action.payload.courses,
                ],
                loading: false,
            };
        });
        builder.addCase(getAdmissionRegister.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAdmissionRegister.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(action.payload.students);
            return {
                ...state,
                students: formated,
                loading: false,
            };
        });
        builder.addCase(getSubjectSessionCount.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjectSessionCount.fulfilled, (state, action) => {
            const formated = formatDataForDataTable(action.payload.subjects);
            return {
                ...state,
                subjectSessionCounts: formated,
                loading: false,
            };
        });
        builder.addCase(deleteSubjectEnrollments.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteSubjectEnrollments.fulfilled, (state, action) => {
            if (action.payload.status === "error") {
                return {
                    ...state,
                    loading: false,
                };
            }

            return {
                ...state,
                subjectSessionCounts: state.subjectSessionCounts.filter(
                    (sub) => sub.subjectEnrollmentId !== action.meta.arg
                ),
                loading: false,
            };
        });

        builder.addCase(
            getSubjectsSessionsCompleated.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getSubjectsSessionsCompleated.fulfilled,
            (state, action) => {
                const formated = formatDataForDataTable(
                    action.payload.subjects
                );
                return {
                    ...state,
                    subjectSessionCompleated: formated,
                    loading: false,
                };
            }
        );

        builder.addCase(getStudentProfile.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getStudentProfile.fulfilled, (state, action) => {
            return {
                ...state,
                studentProfile: action.payload.studentProfile,
                loading: false,
            };
        });

        builder.addCase(getCountries.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCountries.fulfilled, (state, action) => {
            return {
                ...state,
                countries: action.payload,
                loading: false,
            };
        });

        builder.addCase(getStudent.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getStudent.fulfilled, (state, action) => {
            return {
                ...state,
                student: action.payload.student,
                loading: false,
            };
        });

        builder.addCase(getClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getClasses.fulfilled, (state, action) => {
            return {
                ...state,
                classes: action.payload.classes,
                loading: false,
            };
        });

        builder.addCase(getSyllabus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSyllabus.fulfilled, (state, action) => {
            return {
                ...state,
                syllabuses: action.payload.syllabuses,
                loading: false,
            };
        });

        builder.addCase(getMediums.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMediums.fulfilled, (state, action) => {
            return {
                ...state,
                mediums: action.payload.mediums,
                loading: false,
            };
        });

        builder.addCase(updateStudent.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateStudent.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        builder.addCase(getCroPendingEnquiries.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroPendingEnquiries.fulfilled, (state, action) => {
            return {
                ...state,
                pendingEnquiries: formatDataForDataTable(
                    action.payload.data.CroEnquiriesFoundation
                ),
                loading: false,
            };
        });
        // --------------------------------getCroEnquiryLog------------------------------------------

        builder.addCase(getCroEnquiryLog.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroEnquiryLog.fulfilled, (state, action) => {
            return {
                ...state,
                CroEnquiryLog: formatDataForDataTable(
                    action.payload.data.CroEnquiryLogFoundation
                ),
                loading: false,
            };
        });
        // ----------------------------------getCroStudentEnquiryLog------------------------------------

        builder.addCase(getCroStudentEnquiryLog.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroStudentEnquiryLog.fulfilled, (state, action) => {
            return {
                ...state,
                croStudentEnquiryLog: formatDataForDataTable(
                    action.payload.data.StudentEnquiryLogFoundation
                ),
                loading: false,
            };
        });

        builder.addCase(getStudentFeeStructure.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getStudentFeeStructure.fulfilled, (state, action) => {
            return {
                ...state,
                studentFeeStructure: action.payload.studentFeeStructure,
                loading: false,
            };
        });

        builder.addCase(getOgaList.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getOgaList.fulfilled, (state, action) => {
            return {
                ...state,
                OgaList: action.payload.ogas,
                loading: false,
            };
        });

        builder.addCase(addOgaRetainSchedule.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addOgaRetainSchedule.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------------getMobileAppStudents---------------------------

        builder.addCase(getMobileAppStudents.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMobileAppStudents.fulfilled, (state, action) => {
            return {
                ...state,
                mobileAppStudents: formatDataForDataTable(action.payload.data),
                loading: false,
            };
        });
        // -------------------------addStudentToMobileApp----------------------------

        builder.addCase(addStudentToMobileApp.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addStudentToMobileApp.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // -------------------------updateMobileAppStudent-----------------------------

        builder.addCase(updateMobileAppStudent.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateMobileAppStudent.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------deleteMobileAppStudent-----------------------------------------

        builder.addCase(deleteMobileAppStudent.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteMobileAppStudent.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        builder.addCase(getStudentMonthlyLedger.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getStudentMonthlyLedger.fulfilled, (state, action) => {
            const frData = formatDataForDataTable(
                action.payload.studentMonthlyLedger
            );
            return {
                ...state,
                studentMonthlyLedger: frData,
                ledgerAction: action.payload.ledgerAction,
                lgStudentDetails: action.payload.studentDetails,
                nextLedger: action.payload.nextLedger,
                previousLedger: action.payload.previousLedger,
                loading: false,
            };
        });

        builder.addCase(getAccountsSummery.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAccountsSummery.fulfilled, (state, action) => {
            return {
                ...state,
                studentMonthlyLedgerSummary:
                    action.payload.studentMonthlyLedgerSummary,
                loading: false,
            };
        });
        // -----------------------getCROStudentsNotInApp--------------------------------
        builder.addCase(getCROStudentsNotInApp.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCROStudentsNotInApp.fulfilled, (state, action) => {
            return {
                ...state,
                croStudentsNotInApp: action.payload.data.students,

                loading: false,
            };
        });
        // -------------------------------------getCROStudentsEmailIds-------------------------

        builder.addCase(getCROStudentsEmailIds.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCROStudentsEmailIds.fulfilled, (state, action) => {
            return {
                ...state,
                croStudentsEmailIds: action.payload.data.students,

                loading: false,
            };
        });

        builder.addCase(
            getReferralAdmissionRegister.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getReferralAdmissionRegister.fulfilled,
            (state, action) => {
                const frData = formatDataForDataTable(action.payload);
                return {
                    ...state,
                    refAdmissionRegister: frData,
                    loading: false,
                };
            }
        );

        //----------cro enquiries related calls------------------>

        builder.addCase(
            getFoundationPendingEnquiries.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getFoundationPendingEnquiries.fulfilled,
            (state, action) => {
                const frData = formatDataForDataTable(
                    action.payload.croEnquiriesFoundation
                );
                return {
                    ...state,
                    croEnquiriesFoundation: frData,
                    loading: false,
                };
            }
        );

        builder.addCase(
            getClassroomPendingEnquiries.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getClassroomPendingEnquiries.fulfilled,
            (state, action) => {
                const frData = formatDataForDataTable(
                    action.payload.croEnquiriesClassroom
                );
                return {
                    ...state,
                    croEnquiriesClassroom: frData,
                    loading: false,
                };
            }
        );
        // ----------------------------------------dayWiseSessions-------------------------------
        builder.addCase(getDayWiseSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getDayWiseSessions.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.dailySessions
            );
            return {
                ...state,
                dayWiseSessions: data,
                loading: false,
            };
        });
        // ----------------------cancelSession--------------------------------
        builder.addCase(cancelSession.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(cancelSession.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ---------------------------getSessionCancellationReasons-----------------------
        builder.addCase(
            getSessionCancellationReasons.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getSessionCancellationReasons.fulfilled,
            (state, action) => {
                const data = action.payload.data.reasons;

                return {
                    ...state,
                    sessionCancellationReasons: data,
                    loading: false,
                };
            }
        );
        // ----------------------------------reScheduleSession-----------------

        builder.addCase(reScheduleSession.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(reScheduleSession.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ----------------------gettrainerAttendance------------------------------------
        builder.addCase(getTrainerAttendance.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getTrainerAttendance.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.studentSessions
            );
            return {
                ...state,
                trainerAttandace: data,

                loading: false,
            };
        });
        // ----------------------getAttendanceTypes----------------------------
        builder.addCase(getAttendanceTypes.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getAttendanceTypes.fulfilled, (state, action) => {
            const data = action.payload.data.attendanceTypes;

            return {
                ...state,
                attendanceTypes: data,

                loading: false,
            };
        });
        // ----------------------getSubjectTopics-------------------------------
        builder.addCase(getSubjectTopics.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjectTopics.fulfilled, (state, action) => {
            const data = action.payload.data.topics;

            return {
                ...state,
                subjectTopicsPreData: data,

                loading: false,
            };
        });
        // -----------------addTrainerAttendance----------------------------------------
        builder.addCase(addTrainerAttendance.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addTrainerAttendance.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ----------------------------deleteTrainerAttendance---------------------------------
        builder.addCase(deleteTrainerAttendance.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteTrainerAttendance.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        // ------------------------getStudentsForEnrollmentDetailed-----------------------------
        builder.addCase(
            getStudentsForEnrollmentDetailed.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getStudentsForEnrollmentDetailed.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.students
                );

                return {
                    ...state,
                    studentsForEnrollmentDetailed: data,
                    loading: false,
                };
            }
        );
        // -----------------------------------getInvoicesByCourseEnrollment------------------------
        builder.addCase(
            getInvoicesByCourseEnrollment.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getInvoicesByCourseEnrollment.fulfilled,
            (state, action) => {
                const data = action.payload.data?.invoices;

                return {
                    ...state,
                    invoicesByCourseEnrollmentPreData: data,
                    loading: false,
                };
            }
        );
        // ---------------------addCourseEnrollmentDetailed------------------------------
        builder.addCase(
            addCourseEnrollmentDetailed.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            addCourseEnrollmentDetailed.fulfilled,
            (state, action) => {
                return {
                    ...state,
                    loading: false,
                };
            }
        );
        // -------------------------getTpVerificationData-------------------------
        builder.addCase(getTpVerificationData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getTpVerificationData.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.tpVerificationDatas
            );
            return {
                ...state,
                tpVerificationData: data,
                loading: false,
            };
        });
        // -----------------------getTpVerificationDataDetailed---------------------------
        builder.addCase(
            getTpVerificationDataDetailed.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getTpVerificationDataDetailed.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.tpVerificationDataDetailed
                );
                return {
                    ...state,
                    tpVerificationDataDetailed: data,
                    loading: false,
                };
            }
        );
        // ----------------------updateTpVerificationData-----------------------------

        builder.addCase(updateTpVerificationData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(updateTpVerificationData.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------------------getDetailedSubjectEnrollementsByStudent-------------------------------
        builder.addCase(
            getDetailedSubjectEnrollementsByStudent.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDetailedSubjectEnrollementsByStudent.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.subjectEnrollments
                );
                return {
                    ...state,
                    detailedSubjectEnrollementsByStudent: data,

                    loading: false,
                };
            }
        );
        // ------------------------addSubjectEnrollmentDetailed------------------------
        builder.addCase(
            addSubjectEnrollmentDetailed.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            addSubjectEnrollmentDetailed.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // --------------getLevelType-----------------------------
        builder.addCase(getLevelType.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLevelType.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                levelTypePreData: data,

                loading: false,
            };
        });
        // ------------------getSubjectsByClass----------------------------------
        builder.addCase(getSubjectsByClass.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjectsByClass.fulfilled, (state, action) => {
            const data = action.payload.data;
            return {
                ...state,
                subjectsByClassPreData: data,

                loading: false,
            };
        });
        // -----------------------getProjectCategories-------------------------------

        builder.addCase(getProjectCategories.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjectCategories.fulfilled, (state, action) => {
            const data = action.payload.data;

            return {
                ...state,
                projectCategoriesPreData: data,

                loading: false,
            };
        });
        // ----------------------------getDetailedSubjectEnrollementsByCourse---------------------------------

        builder.addCase(
            getDetailedSubjectEnrollementsByCourse.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDetailedSubjectEnrollementsByCourse.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.subjectEnrollments
                );

                return {
                    ...state,
                    detailedSubjectEnrollementsByCourse: data,
                    courseEnrollmentDtldId:
                        action.payload.data.courseEnrollmentDtldId,

                    loading: false,
                };
            }
        );
        // --------------------------getDetailedCourseEnrollementsByStudent--------------------------
        builder.addCase(
            getDetailedCourseEnrollementsByStudent.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDetailedCourseEnrollementsByStudent.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.courseEnrollments[0]
                        .detailedCourseEnrollments
                );

                return {
                    ...state,
                    detailedCourseEnrollementsByStudent: data,

                    loading: false,
                };
            }
        );
        // ---------------------------getDetailedCourseEnrollementsByCourse--------------------
        builder.addCase(
            getDetailedCourseEnrollementsByCourse.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getDetailedCourseEnrollementsByCourse.fulfilled,
            (state, action) => {
                const data = action.payload.data.detailedCourseEnrollment[0];

                return {
                    ...state,
                    detailedCourseEnrollementsByCourse: data,

                    loading: false,
                };
            }
        );
        // ----------------------updateCourseEnrollmentDetailed-------------------------
        builder.addCase(
            updateCourseEnrollmentDetailed.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            updateCourseEnrollmentDetailed.fulfilled,
            (state, action) => {
                return {
                    ...state,
                    loading: false,
                };
            }
        );
        // ---------------------getAllStudentClassRestartedDates--------------------------
        builder.addCase(
            getAllStudentClassRestartedDates.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            getAllStudentClassRestartedDates.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.getAllRestartedDates
                );

                console.log("data", data);
                return {
                    ...state,
                    classRestartDates: data,
                    loading: false,
                };
            }
        );
        // -----------------croStudents--------------------------
        builder.addCase(getCroStudents.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCroStudents.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.students);
            return {
                ...state,
                croStudents: data,
                loading: false,
            };
        });
        // ---------------------getAllAttendensPerStudent------------------------
        builder.addCase(getAllAttendensPerStudent.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getAllAttendensPerStudent.fulfilled,
            (state, action) => {
                const data = action.payload.data[0];
                console.log("data", data);

                return {
                    ...state,
                    allAttendensPerStudent: data,
                    loading: false,
                };
            }
        );
    },
});

export const {
    getEnrolledSubjects,
    storeDate,
    saveAdmissionRegFilter,
    clearStudent,
    clearStudentFeeStructure,
    navigatedDate,
    clearStudentAttendance,
} = croSlice.actions;

export default croSlice.reducer;
