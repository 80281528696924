import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    data: [],
    loading: false,
    error: "",
    analytics: [],
    applications: [],
    singleApplication: {},
    leaveBypassCounts: [],
};

export const getLeaveTypes = createAsyncThunk(
    "leave/getLeaveTypes",
    async () => {
        const res = await apiRequest({
            method: "get",
            url: "leave/getLeaveTypes",
        });
        return res;
    }
);

export const getLeaveAnalytics = createAsyncThunk(
    "leave/getAnalytics",
    async (e) => {
        const res = await apiRequest({
            method: "get",
            url: `analytics/leaveAnalytics/${e}`,
        });
        return res;
    }
);
export const applyLeave = createAsyncThunk("leave/apply", async (e) => {
    const res = await apiRequest({
        url: "leave/apply",
        method: "post",
        data: e,
    });
    return res;
});

export const getLeaveAplictions = createAsyncThunk(
    "leave/getApplications",
    async (year) => {
        const res = await apiRequest({
            url: `leave/getLeaveApplications/${year}`,
            method: "get",
        });
        return res;
    }
);

export const getLeaveAplictionsforReportingHead = createAsyncThunk(
    "leave/getApplications_Rph",
    async (filter) => {
        const res = await apiRequest({
            url: `leave/getReportingHeadLeaveApplications/${filter}`,
            method: "get",
        });
        return res;
    }
);

export const getLeaveAplictionsforHr = createAsyncThunk(
    "leave/get_applications_hr",
    async (str) => {
        const res = await apiRequest({
            url: `leave/getAllLeaveApplications/${str}`,
            method: "get",
        });

        return res;
    }
);

export const getLeaveApplicationById = createAsyncThunk(
    "leave/leave_apltn_by_id",
    async (id) => {
        const res = await apiRequest({
            method: "get",
            url: `leave/getLeaveApplication/${id}`,
        });
        return res;
    }
);

export const editLeave = createAsyncThunk("leave/edit", async (values) => {
    const res = await apiRequest({
        url: "leave/editLeaveApplication",
        method: "put",
        data: values,
    });
    return res;
});

export const deleteLeave = createAsyncThunk("leave/delete", async (id) => {
    const res = await apiRequest({
        method: "delete",
        url: `leave/deleteLeaveApplication/${id}`,
    });
    return res;
});

export const getBypassCount = createAsyncThunk(
    "leave/bypassCount",
    async (date) => {
        const res = await apiRequest({
            method: "get",
            url: `analytics/leaveBypassCounts/${date}`,
        });
        return res;
    }
);

export const approveLeaveReportingHead = createAsyncThunk(
    "leave/approve_leave_hod",
    async (data) => {
        const res = await apiRequest({
            url: "leave/reportingHeadApproval",
            method: "post",
            data: data,
        });

        return res;
    }
);

export const approveLeaveHr = createAsyncThunk(
    "leave/approve_leave_Hr",
    async (data) => {
        const res = await apiRequest({
            url: "leave/HRApproval",
            method: "post",
            data: data,
        });

        return res;
    }
);

const leaveSlice = createSlice({
    name: "leave",
    initialState,
    reducers: {
        clearApplications: (state, action) => {
            return {
                ...state,
                applications: [],
            };
        },
        clearAllLeaveData: (state) => {
            state = initialState;
            return state;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getLeaveTypes.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(getLeaveTypes.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload.data.leaveTypes;
        });
        builder.addCase(getLeaveTypes.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.message;
        });

        // leave analytics

        builder.addCase(getLeaveAnalytics.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLeaveAnalytics.fulfilled, (state, action) => {
            return {
                ...state,
                analytics: action.payload.data.analytics,
                loading: false,
            };
        });

        // apply leave

        builder.addCase(applyLeave.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(applyLeave.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });

        // get leave applications

        builder.addCase(getLeaveAplictions.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLeaveAplictions.fulfilled, (state, action) => {
            const applications = formatDataForDataTable(action.payload.data);
            return {
                ...state,
                applications: applications,
                loading: false,
            };
        });

        // getLeaveApplicationBy id

        builder.addCase(getLeaveApplicationById.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(getLeaveApplicationById.fulfilled, (state, action) => {
            return {
                ...state,
                singleApplication: action.payload.data,
                loading: false,
            };
        });

        // get leave applications for reporting head

        builder.addCase(getLeaveAplictionsforReportingHead.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getLeaveAplictionsforReportingHead.fulfilled,
            (state, action) => {
                const applications = formatDataForDataTable(
                    action.payload.data.leaveTypes
                );
                return {
                    ...state,
                    applications: applications,
                    loading: false,
                };
            }
        );
        // leave applications for hr
        builder.addCase(getLeaveAplictionsforHr.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getLeaveAplictionsforHr.fulfilled, (state, action) => {
            const applications = formatDataForDataTable(
                action.payload.data.leaveTypes
            );
            return {
                ...state,
                applications: applications,
                loading: false,
            };
        });

        // delete leave

        builder.addCase(deleteLeave.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(deleteLeave.fulfilled, (state, action) => {
            return {
                ...state,
                loading: false,
            };
        });
        //bypassCount
        builder.addCase(getBypassCount.pending, (state) => {
            return {
                ...state,
                loading: true,
            };
        });

        builder.addCase(getBypassCount.fulfilled, (state, action) => {
            const leaveBypassCounts = formatDataForDataTable(
                action.payload.data.leaveBypassCounts
            );
            return {
                ...state,
                leaveBypassCounts: leaveBypassCounts,
                loading: false,
            };
        });
    },
});
export const { clearAllLeaveData, clearApplications } = leaveSlice.actions;
export default leaveSlice.reducer;
