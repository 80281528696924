import React, { useRef, useState } from "react";
import Page from "../../../../components/Page";
import {
    Typography,
    Container,
    Paper,
    Box,
    MenuItem,
    InputLabel,
    Select,
    FormControl,
    TextField,
    Stack,
    Button,
    Divider,
    FormHelperText,
} from "@mui/material";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Icon } from "@iconify/react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import { useEffect } from "react";
import { useSelector, useReducer, useDispatch } from "react-redux";
import useResponsive from "../../../../Hooks/useResponsive";
import {
    applyLeave,
    getLeaveTypes,
} from "../../../../redux/features/leaveSlice";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { useSnackbar } from "notistack";
import * as yup from "yup";

export default function LeaveApplication() {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const leaveTypes = useSelector((state) => state.leave);
    const dispatch = useDispatch();
    const [fromDate, setFrom] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [availableLeave, setAvailable] = useState("Not-Available");
    const [displayDuration, SetDisplayDuration] = useState(false);
    const [duration, setDuration] = useState("");
    const [numberOfDays, setNumberOfDays] = useState("");
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(getLeaveTypes());
    }, []);

    const validationSchema = yup.object().shape({
        leaveTypeId: yup.number().required("please select leave type"),
        fromDate: yup.string().required("please select From Date"),
        toDate: yup.string().required("please select To date"),
        halfday: yup.boolean().when({
            is: () => {
                return numberOfDays === 1 || numberOfDays === 0.5;
            },
            then: yup.boolean().required("this feild is required"),
        }),
        reason: yup.string().required("please Enter the reason for your leave"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            leaveTypeId: "",
            fromDate: "",
            toDate: "",
            halfday: "",
            reason: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (values.halfday === "") {
                values.halfday = false;
            }
            dispatch(applyLeave(values)).then((res) => {
                if (res.payload.status === "error") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "error",
                    });
                } else if (res.payload.status === "success") {
                    enqueueSnackbar(res.payload.message, {
                        variant: "success",
                    });
                    navigate("/employeeservices/applications");
                }
            });
        },
    });

    const handleChangeFrom = (e) => {
        setFrom(e);
        if (e !== null && JSON.stringify(e.$d) !== "null") {
            formik.setFieldValue("fromDate", e.toISOString().split("T")[0]);
        }
    };
    const handleChangeTo = (e) => {
        setToDate(e);
        if (e !== null && JSON.stringify(e.$d) !== "null") {
            formik.setFieldValue("toDate", e.toISOString().split("T")[0]);
        }
    };

    const handleDuration = (e) => {
        if (e.target.value === "Half-Day") {
            setDuration(e.target.value);
            formik.setFieldValue("halfday", true);
            setNumberOfDays(0.5);
        } else {
            setDuration(e.target.value);
            formik.setFieldValue("halfday", false);
            setNumberOfDays(1);
        }
    };

    useEffect(() => {
        if (formik.values.leaveTypeId) {
            const selectedLeave = leaveTypes.data.filter(
                (item) => item.leaveTypeId === formik.values.leaveTypeId
            );
            if (selectedLeave[0].leavesRemaining !== "Not Applicable") {
                setAvailable(selectedLeave[0].leavesRemaining);
            } else {
                setAvailable("Not-available");
            }
        }
    }, [formik.values.leaveTypeId]);

    useEffect(() => {
        setDuration("");
        if (fromDate && toDate) {
            const diff =
                new Date(fromDate).getTime() - new Date(toDate).getTime();
            const numOfDays = Math.floor(
                Math.abs(diff / (1000 * 60 * 60 * 24))
            );
            setNumberOfDays(numOfDays + 1);
            if (numOfDays <= 0) {
                SetDisplayDuration(true);
            } else {
                SetDisplayDuration(false);
            }
        }
    }, [fromDate, toDate]);
    return (
        <div>
            <Page title="Create Additional Session">
                <Container component="main">
                    {/*..................................HEADING.............................*/}
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Apply for Leave
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />

                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {smUp && (
                            <Button
                                component={Link}
                                to={`/employeeservices/applications`}
                                sx={{
                                    backgroundColor: "#7e4d8b45",
                                    color: "#7e4d8b",
                                }}
                            >
                                <Icon
                                    icon="mdi:eye-settings"
                                    width="30"
                                    height="30"
                                />
                                View Applications
                            </Button>
                        )}
                        {!smUp && (
                            <Button
                                component={Link}
                                size="small"
                                to={`/employeeservices/applications`}
                                sx={{
                                    backgroundColor: "#7e4d8b45",
                                    color: "#7e4d8b",
                                }}
                            >
                                <Icon
                                    icon="mdi:eye-settings"
                                    width="30"
                                    height="30"
                                />
                                View Applications
                            </Button>
                        )}
                    </Box>

                    {smUp && (
                        <Paper elevation={3} sx={{ width: "100%", margin: 3 }}>
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box sx={{ padding: 6 }}>
                                        <Box sx={{ minWidth: 120, margin: 2 }}>
                                            <GlobalSelectField
                                                options={{
                                                    name: "leaveTypeId",
                                                    formik: formik,
                                                    data: leaveTypes.data,
                                                    label: "Leave Type",
                                                    keys: {
                                                        id: "leaveTypeId",
                                                        value: "leaveNameFull",
                                                    },
                                                    sx: { width: "100%" },
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                label="Available Leave"
                                                fullWidth
                                                value={availableLeave}
                                                disabled
                                                variant="filled"
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <Stack
                                                direction={"row"}
                                                spacing={2}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="From Date"
                                                        value={fromDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        onChange={
                                                            handleChangeFrom
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                {...params}
                                                                error={Boolean(
                                                                    formik
                                                                        .errors
                                                                        .fromDate
                                                                )}
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .fromDate
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>

                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="To Date"
                                                        value={toDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        name="date"
                                                        onChange={
                                                            handleChangeTo
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                {...params}
                                                                error={Boolean(
                                                                    formik
                                                                        .errors
                                                                        .toDate
                                                                )}
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .toDate
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                        </Box>

                                        {displayDuration && (
                                            <Box sx={{ margin: 2 }}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(
                                                        formik.errors.halfday
                                                    )}
                                                >
                                                    <InputLabel id="Input-Label">
                                                        Duration of Leave
                                                    </InputLabel>
                                                    <Select
                                                        labelId="Input-Label"
                                                        label="Duration of Leave"
                                                        onChange={
                                                            handleDuration
                                                        }
                                                        value={duration}
                                                    >
                                                        <MenuItem value="Half-Day">
                                                            Half-Day
                                                        </MenuItem>
                                                        <MenuItem value="Full-Day">
                                                            Full-Day
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        {formik.errors.halfday}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Box>
                                        )}

                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                label="No.of Days"
                                                fullWidth
                                                disabled
                                                variant="filled"
                                                value={numberOfDays}
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                name="reason"
                                                label="Reason"
                                                placeholder="Enter the reason for leave"
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                minRows={4}
                                                onChange={formik.handleChange}
                                                error={Boolean(
                                                    formik.errors.reason
                                                )}
                                                helperText={
                                                    formik.errors.reason
                                                }
                                            />
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Button variant="outlined">
                                                Reset
                                            </Button>

                                            <Button
                                                variant="contained"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    )}
                    {!smUp && (
                        <Paper
                            elevation={3}
                            sx={{ width: "100%", marginTop: 3 }}
                        >
                            <Box
                                sx={{
                                    height: 48,
                                    backgroundColor: "grey.200",
                                    borderRadius: "8px 8px 0 0",
                                }}
                            ></Box>
                            <FormikProvider value={formik}>
                                <Form onSubmit={formik.handleSubmit}>
                                    <Box sx={{ padding: 2 }}>
                                        <Box sx={{ minWidth: 120, margin: 2 }}>
                                            <GlobalSelectField
                                                options={{
                                                    name: "leaveTypeId",
                                                    formik: formik,
                                                    data: leaveTypes.data,
                                                    label: "Leave Type",
                                                    keys: {
                                                        id: "leaveTypeId",
                                                        value: "leaveNameFull",
                                                    },
                                                    sx: { width: "100%" },
                                                }}
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                label="Available Leave"
                                                fullWidth
                                                value={availableLeave}
                                                disabled
                                                variant="filled"
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <Stack
                                                direction={"column"}
                                                spacing={2}
                                            >
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="From Date"
                                                        value={fromDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        onChange={
                                                            handleChangeFrom
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                {...params}
                                                                error={Boolean(
                                                                    formik
                                                                        .errors
                                                                        .fromDate
                                                                )}
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .fromDate
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>

                                                <LocalizationProvider
                                                    dateAdapter={AdapterDayjs}
                                                >
                                                    <DatePicker
                                                        label="To Date"
                                                        value={toDate}
                                                        inputFormat="DD/MM/YYYY"
                                                        name="date"
                                                        onChange={
                                                            handleChangeTo
                                                        }
                                                        renderInput={(
                                                            params
                                                        ) => (
                                                            <TextField
                                                                fullWidth
                                                                {...params}
                                                                error={Boolean(
                                                                    formik
                                                                        .errors
                                                                        .toDate
                                                                )}
                                                                helperText={
                                                                    formik
                                                                        .errors
                                                                        .toDate
                                                                }
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            </Stack>
                                        </Box>

                                        {displayDuration && (
                                            <Box sx={{ margin: 2 }}>
                                                <FormControl
                                                    fullWidth
                                                    error={Boolean(
                                                        formik.errors.halfday
                                                    )}
                                                >
                                                    <InputLabel id="Input-Label">
                                                        Duration of Leave
                                                    </InputLabel>
                                                    <Select
                                                        labelId="Input-Label"
                                                        label="Duration of Leave"
                                                        onChange={
                                                            handleDuration
                                                        }
                                                        value={duration}
                                                    >
                                                        <MenuItem value="Half-Day">
                                                            Half-Day
                                                        </MenuItem>
                                                        <MenuItem value="Full-Day">
                                                            Full-Day
                                                        </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                        {formik.errors.halfday}
                                                    </FormHelperText>
                                                </FormControl>
                                            </Box>
                                        )}

                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                label="No.of Days"
                                                fullWidth
                                                disabled
                                                variant="filled"
                                                value={numberOfDays}
                                            />
                                        </Box>
                                        <Box sx={{ margin: 2 }}>
                                            <TextField
                                                name="reason"
                                                label="Reason"
                                                placeholder="Enter the reason for leave"
                                                fullWidth
                                                variant="outlined"
                                                multiline
                                                minRows={4}
                                                onChange={formik.handleChange}
                                                error={Boolean(
                                                    formik.errors.reason
                                                )}
                                                helperText={
                                                    formik.errors.reason
                                                }
                                            />
                                        </Box>
                                        <Divider />
                                        <Box
                                            sx={{
                                                paddingTop: 5,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                            }}
                                        >
                                            <Button variant="outlined">
                                                Reset
                                            </Button>

                                            <Button
                                                variant="contained"
                                                type="submit"
                                                value="Save"
                                                sx={{ marginLeft: 2 }}
                                            >
                                                Save
                                            </Button>
                                        </Box>
                                    </Box>
                                </Form>
                            </FormikProvider>
                        </Paper>
                    )}
                </Container>
            </Page>
        </div>
    );
}
