import React from "react";
import Page from "../../../components/Page";
import { Box, Button, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { Link } from "react-router-dom";
import { EXPENSE_TABLE_HEAD } from "../../../components/datagrid/myworkspace/cro/ExpenseTableHead";

const ExpensePage = () => {
    return (
        <Page>
            <Box>
                <Typography variant="h4" sx={{ mb: 1 }}>
                    Expense
                </Typography>
                <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                <Paper
                    elevation={3}
                    sx={{ borderRadius: 1, width: "100%", mt: 4 }}
                >
                    <Box
                        sx={{
                            padding: 1,
                            backgroundColor: "grey.200",
                            borderRadius: "8px 8px 0 0",
                            display: "flex",
                            justifyContent: "right",
                        }}
                    >
                        <Button
                            variant="contained"
                            component={Link}
                            to={"/my-tasks/cro-add-expense"}
                        >
                            Add Expense
                        </Button>
                    </Box>
                    <DataGrid
                        columns={EXPENSE_TABLE_HEAD}
                        rows={[]}
                        autoHeight
                    />
                </Paper>
            </Box>
        </Page>
    );
};

export default ExpensePage;
