import AttendanceNameImg from "./AttendanceNameImg";
import MarkAbsentees from "./MarkAbsentees";
export const MARKABSENTEES_TABLE_HEAD = [
  {
    field: "name",
    flex: 1,
    minWidth: 300,
    headerName: "Name",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => AttendanceNameImg(params),
  },
  {
    field: "Action",
    flex: 1,
    minWidth: 100,
    headerName: "Action",
    type: "string",
    headerAlign: "left",
    align: "left",
    renderCell: (params) => MarkAbsentees(params),
  },
];
