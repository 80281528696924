import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider, Paper } from "@mui/material";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useSnackbar } from "notistack";
import { markAbsentees } from "../../../redux/features/attendanceSlice";
import useResponsive from "../../../Hooks/useResponsive";
import { Icon } from "@iconify/react";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MarkAbsentees(params) {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.attendance.loading);

    const [value, setValue] = React.useState(dayjs());
    useEffect(() => {
        const date = dayjs();
        setValue(date.toISOString().split("T")[0]);
    }, []);

    const handleChange = (newValue) => {
        setValue(newValue);
    };

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        const data = {
            date: value.toISOString().split("T")[0],
            userId: params.row.userId,
        };
        dispatch(markAbsentees(data)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
                setOpen(false);
            }
        });
    };

    return (
        <div>
            <Button onClick={handleClickOpen}>
                <Icon icon="iconoir:cancel" width="25" height="25" />
                Mark Absent
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box sx={{ backgroundColor: "grey.200" }}>
                    <DialogTitle>{"Mark Absent..."}</DialogTitle>
                </Box>
                <Divider />
                <DialogContent>
                    {smUp && (
                        <Box sx={{ width: 400 }}>
                            <Paper sx={{ padding: 2 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DesktopDatePicker
                                        label="Select Date"
                                        inputFormat="YYYY/MM/DD"
                                        value={value}
                                        mask=""
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Paper>
                        </Box>
                    )}
                    {!smUp && (
                        <Box sx={{ width: "100%" }}>
                            <Paper sx={{ padding: 2 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DesktopDatePicker
                                        label="Select Date"
                                        inputFormat="YYYY/MM/DD"
                                        value={value}
                                        mask=""
                                        onChange={handleChange}
                                        renderInput={(params) => (
                                            <TextField
                                                fullWidth
                                                variant="filled"
                                                {...params}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            </Paper>
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        sx={{ backgroundColor: "#eff4f9", color: "#2f72b3" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        sx={{ backgroundColor: "#ffe4de", color: "#c03530" }}
                    >
                        {loading ? "Marking..." : "Mark"}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
