import React from "react";
import Page from "../../../components/Page";
import { Button, Paper, Stack, TextField, Typography } from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import BasicTextField from "../../../components/mui/TextField";

const AddExpense = () => {
    const handleFileUpload = () => {};
    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => {
            console.log(values, "-=-=-=-=");
        },
    });

    //<-----------------dummy data for ui development------------------->

    const expenseType = [
        { id: 1, expenseType: "Refund" },
        { id: 3, expenseType: "Bad debt" },
        { id: 2, expenseType: "Term fee" },
    ];

    return (
        <Page>
            <Typography variant="h4" sx={{ mb: 1 }}>
                Add expenses
            </Typography>
            <Breadcrumb sx={{ marginBottom: 1 }} />
            <Paper elevation={4} sx={{ width: "100%", padding: 3 }}>
                <FormikProvider value={formik}>
                    <Form onSubmit={formik.handleSubmit}>
                        <Stack gap={2}>
                            <GlobalSelectField
                                options={{
                                    label: "Fee type",
                                    data: expenseType,
                                    keys: {
                                        id: "id",
                                        value: "expenseType",
                                    },
                                }}
                            />
                            <GlobalSelectField
                                options={{
                                    label: "Student Name",
                                }}
                            />
                            {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    label="Transaction Date"
                                    onChange={(e) => {}}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </LocalizationProvider> */}

                            <BasicTextField label="Amount" type="number" />

                            <BasicTextField
                                multiline
                                rows={4}
                                label="Remarks / Refference Number"
                                placeholder="Type your Remarks here"
                            />

                            <Button
                                variant="contained"
                                type="submit"
                                sx={{
                                    width: "300px",
                                    margin: "0 auto",
                                }}
                            >
                                Add Income
                            </Button>
                        </Stack>
                    </Form>
                </FormikProvider>
            </Paper>
        </Page>
    );
};

export default AddExpense;
