import React from "react";
import GlobalDialog from "../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";

export const Screenshot = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Screenshot"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"md"}
                closeButton={true}
                popupButton={
                    <Button
                        variant="contained"
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        View
                    </Button>
                }
            />
        </div>
    );
};
