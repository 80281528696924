import { Routes, Route } from "react-router-dom";
import { PublicRoute } from "./routing/router";
import PublicRouter from "./routing/router/PublicRouter";
import Page404 from "./views/user/Auth/Page404";
import ProfileCompleationCheck from "./routing/route/ProfileCompleationCheck";
import MainRouter from "./routing/router/MainRouter";
import ProfileRoute from "./routing/route/ProfileRoute";
import Index from "./views/user/Auth/ProfileCompletion/Index";

export default function Router() {
    return (
        <Routes>
            <Route
                path="/auth/*"
                element={
                    <PublicRoute>
                        <PublicRouter />
                    </PublicRoute>
                }
            />

            <Route
                path="/fill/*"
                element={
                    <ProfileRoute>
                        <Routes>
                            <Route path="/detailsnew" element={<Index />} />
                        </Routes>
                    </ProfileRoute>
                }
            />

            <Route
                path="/*"
                element={
                    <ProfileCompleationCheck>
                        <MainRouter />
                    </ProfileCompleationCheck>
                }
            />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
}
