import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "../../components/sidebar";
import {
    ProtectedRoute,
    DashboardRoute,
    MyWorkspaceRoute,
    NotificationRoute,
    MenuRoute,
    ScheduleRoute,
    TodoRoute,
    EmployeeServiceRoute,
    HodRoute,
    RefferelRoute,
    AnalyticsRoute,
    MasterRoute,
} from "../route/index";
import DashboardRouter from "./DashboardRouter";
import NotificationRouter from "./NotificationRouter";
import MyWorkspaceRouter from "./MyWorkspaceRouter";
import MenuRouter from "./MenuRouter";
import ScheduleRouter from "./ScheduleRouter";
import TodoRouter from "./TodoRouter";
import EmployeeServiceRouter from "./EmployeeServiceRouter";
import PrivilegesRouter from "./PrivilegesRouter";
import RefferelRouter from "./RefferelRouter";
import AttendanceCheck from "../route/AttendanceCheck";
import AnalyticsRouter from "./AnalyticsRouter";
import Page404 from "../../views/user/Auth/Page404";
import MasterRouter from "./MasterRouter";

const MainRouter = () => {
    return (
        <Routes>
            <Route
                element={
                    <ProtectedRoute>
                        <DashboardLayout />
                    </ProtectedRoute>
                }
            >
                <Route element={<AttendanceCheck />}>
                    <Route
                        index
                        path="/"
                        element={<Navigate to="/dashboard" />}
                    />

                    <Route
                        path="/dashboard/*"
                        element={
                            <DashboardRoute>
                                <DashboardRouter />
                            </DashboardRoute>
                        }
                    />
                    {/* <Route path="/feedback" element={<Feedback />} /> */}
                    {/* <Route path="/mystudents" element={<MyStudents />} /> */}

                    <Route
                        path="my-workspace/*"
                        element={
                            <MyWorkspaceRoute>
                                <MyWorkspaceRouter />
                            </MyWorkspaceRoute>
                        }
                    />

                    <Route
                        path="masters/*"
                        element={
                            <MasterRoute>
                                <MasterRouter />
                            </MasterRoute>
                        }
                    />

                    <Route
                        path="analytics/*"
                        element={
                            <AnalyticsRoute>
                                <AnalyticsRouter />
                            </AnalyticsRoute>
                        }
                    />

                    <Route
                        path="/notifications/*"
                        element={
                            <NotificationRoute>
                                <NotificationRouter />
                            </NotificationRoute>
                        }
                    />

                    <Route
                        path="/menu/*"
                        element={
                            <MenuRoute>
                                <MenuRouter />
                            </MenuRoute>
                        }
                    />

                    <Route
                        path="/schedules/*"
                        element={
                            <ScheduleRoute>
                                <ScheduleRouter />
                            </ScheduleRoute>
                        }
                    />
                    <Route
                        path="todo/*"
                        element={
                            <TodoRoute>
                                <TodoRouter />
                            </TodoRoute>
                        }
                    />

                    <Route
                        path="employeeservices/*"
                        element={
                            <EmployeeServiceRoute>
                                <EmployeeServiceRouter />
                            </EmployeeServiceRoute>
                        }
                    />

                    <Route
                        path="/privileges/*"
                        element={
                            <HodRoute>
                                <PrivilegesRouter />
                            </HodRoute>
                        }
                    />

                    <Route
                        path="reffer/*"
                        element={
                            <RefferelRoute>
                                <RefferelRouter />
                            </RefferelRoute>
                        }
                    />
                </Route>
            </Route>
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default MainRouter;
