import * as React from "react";
import {
    Stack,
    Box,
    Avatar,
    Typography,
    Container,
    Divider,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import { StudentProfile } from "./StudentProfile";
import { useSelector } from "react-redux";
import Wrapper from "../../../../Wrapper/Wrapper";
import useResponsive from "../../../../../Hooks/useResponsive";
import SimpleLoader from "../../../../Loader/SimpleLoader";

export const StudentProfileData = React.memo(() => {
    const smUp = useResponsive("up", "sm");

    const mdUp = useResponsive("up", "md");
    const data = useSelector((state) => state.crm);
    return (
        <div>
            <Wrapper
                Content={
                    <>
                        {data.loading ? (
                            <SimpleLoader />
                        ) : (
                            <Box height={400}>
                                <Paper
                                    elevation={2}
                                    sx={{
                                        width: "100%",
                                        height: 170,
                                        backgroundSize: "contain",
                                        backgroundSize: "cover",
                                        backgroundImage: `url(${"https://t4.ftcdn.net/jpg/02/17/89/41/360_F_217894165_H4jRalQ4eg9Kp8XUVGEa7XFDEPtTQ9PY.jpg"})`,
                                        borderTopRightRadius: smUp ? 5 : 60,
                                        borderTopLeftRadius: 60,
                                        borderBottomLeftRadius: 5,
                                        borderBottomRightRadius: 5,
                                    }}
                                >
                                    <Box
                                        paddingTop={smUp ? 17 : 5}
                                        paddingLeft={smUp ? 5 : 0}
                                        sx={{
                                            display: smUp ? null : "flex",
                                            justifyContent: smUp
                                                ? null
                                                : "center",
                                        }}
                                    >
                                        <Stack
                                            direction={smUp ? "row" : "column"}
                                        >
                                            <Avatar
                                                src={`https://thumbs.dreamstime.com/b/child-girl-schoolgirl-elementary-school-student-123686003.jpg`}
                                                sx={{
                                                    width: 98,
                                                    height: 100,
                                                    border: 3,
                                                    borderColor: "#e5e8eb",
                                                }}
                                            />
                                            <Box margin={2} marginTop={5}>
                                                <Typography
                                                    variant="h6"
                                                    sx={{
                                                        color: "black",
                                                    }}
                                                >
                                                    {data?.studentProfile
                                                        .firstName
                                                        ? data.studentProfile
                                                              .firstName
                                                        : "No Name"}
                                                </Typography>
                                                <Stack direction={"row"}>
                                                    <Typography
                                                        variant="body2"
                                                        color="grey.500"
                                                        textTransform="capitalize"
                                                    >
                                                        {data?.studentProfile
                                                            .className
                                                            ? data
                                                                  .studentProfile
                                                                  .className
                                                            : "no class"}
                                                    </Typography>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Paper>

                                <Box sx={{ backgroundColor: "grey.200" }}>
                                    <Box padding={1} paddingTop={10}>
                                        <Divider
                                            sx={{ borderBottomWidth: 3 }}
                                        />

                                        <StudentProfile data={data} />
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </>
                }
            />
        </div>
    );
});
