import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Box, Button, Stack, TextField } from "@mui/material";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import {
    CancelButton,
    DeleteButton,
    SubmitButton,
} from "../../../../mui/Buttons/Buttons";

export const AddPaymentInvoice = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            {" "}
            <GlobalDialog
                title="Add Payment Invoice"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                popupButton={
                    <CancelButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Add Payment Invoice
                    </CancelButton>
                }
                content={
                    <Box padding={3}>
                        <Stack direction={"column"} spacing={2}>
                            <Box>
                                <TextField label="Total Amount " fullWidth />
                            </Box>
                            <Box>
                                <TextField label="Fees Paid" fullWidth />
                            </Box>
                            <Box>
                                <TextField label="Balance Amount " fullWidth />
                            </Box>
                            <Box>
                                <TextField label="Payment Amount " fullWidth />
                            </Box>
                            <Box>
                                <GlobalSelectField
                                    options={{
                                        // formik: formik,
                                        label: "Course",
                                        name: "courseId",
                                        sx: { width: "100%" },
                                        // data: data.coursesPreData,

                                        keys: {
                                            id: "courseId",
                                            value: "courseName",
                                        },
                                    }}
                                    variant="filled"
                                />
                            </Box>
                        </Stack>
                    </Box>
                }
                actionButton={
                    <>
                        <CancelButton>Cancel</CancelButton>
                        <SubmitButton>Submit</SubmitButton>
                    </>
                }
            />
        </div>
    );
};
