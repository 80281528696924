import { Icon } from "@iconify/react";
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";
import React from "react";
import { Screenshot } from "../../../../components/datagrid/CRM/Income/Screenshot";

export const Actions = () => {
    return (
        <div>
            {" "}
            <Box padding={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Screenshot
                                        </Typography>
                                    </ListItemText>
                                    <Screenshot />
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Duplicate
                                        </Typography>
                                    </ListItemText>
                                    <Button variant="outlined">Mark</Button>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
