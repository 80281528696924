import React from "react";
import Page from "../../../components/Page";
import {
    Box,
    Button,
    Container,
    FormLabel,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import { Form, FormikProvider, useFormik } from "formik";
import GlobalSelectField from "../../../components/mui/GlobalSelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BasicTextField from "../../../components/mui/TextField";

const AddIncome = () => {
    const formik = useFormik({
        initialValues: {},
        onsubmit: (values) => {
            console.log("submitted", values);
        },
    });

    const handleFileUpload = (e) => {
        console.log("uploadedfile", e.target.files[0]);
    };

    //<------------ trial datas for the form -------->

    const feeType = [
        { id: 1, feeType: "Admission fee" },
        { id: 2, feeType: "Tution fee" },
    ];

    const accountType = [{ type: "GCC Bank A/C" }, { type: "Indian Bank A/C" }];

    const paymentMode = [
        { mode: "Bank Tranfer" },
        { mode: "UPI" },
        { mode: "Cash" },
    ];

    return (
        <div>
            <Page>
                <Container>
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        Add Income
                    </Typography>
                    <Breadcrumb sx={{ marginBottom: 1 }} />
                    <Paper elevation={4} sx={{ width: "100%", padding: 3 }}>
                        <FormikProvider value={formik}>
                            <Form onSubmit={formik.handleSubmit}>
                                <Stack gap={2}>
                                    <GlobalSelectField
                                        options={{
                                            label: "Fee type",
                                            data: feeType,
                                            keys: {
                                                id: "id",
                                                value: "feeType",
                                            },
                                        }}
                                    />
                                    <GlobalSelectField
                                        options={{
                                            label: "Student Name",
                                        }}
                                    />
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            label="Transaction Date"
                                            onChange={(e) => {}}
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <GlobalSelectField
                                        options={{
                                            label: "Amount Credited to",
                                            data: accountType,
                                            keys: {
                                                id: "type",
                                                value: "type",
                                            },
                                        }}
                                    />
                                    <BasicTextField
                                        label="Amount"
                                        type="number"
                                    />
                                    <GlobalSelectField
                                        options={{
                                            label: "Payment Mode",
                                            data: paymentMode,
                                            keys: { id: "mode", value: "mode" },
                                        }}
                                    />

                                    <TextField
                                        type="file"
                                        onChange={handleFileUpload}
                                    />

                                    <BasicTextField
                                        multiline
                                        rows={4}
                                        label="Remarks"
                                        placeholder="Type your Remarks here"
                                    />

                                    <Button
                                        variant="contained"
                                        type="submit"
                                        sx={{
                                            width: "300px",
                                            margin: "0 auto",
                                        }}
                                    >
                                        Add Income
                                    </Button>
                                </Stack>
                            </Form>
                        </FormikProvider>
                    </Paper>
                </Container>
            </Page>
        </div>
    );
};

export default AddIncome;
