import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const settingsSlice = createSlice({
    name: "settings",
    initialState: {
        loading: false,
        screenStatus: {},
        transitionStatus: [{ transition: "Fade", otherProps: "" }],
        sideBarSettings: [],
        error: "",
    },
    reducers: {
        screenStatus: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                screenStatus: action.payload,
            };
        },
        transitionStatus: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                transitionStatus: action.payload,
            };
        },
        sideBarSettings: (state, action) => {
            console.log(action.payload);
            return {
                ...state,
                sideBarSettings: action.payload,
            };
        },
    },
});

export const { screenStatus, transitionStatus, sideBarSettings } =
    settingsSlice.actions;

export default settingsSlice.reducer;
