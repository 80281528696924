import React, { useState } from "react";
import { ApproveButton } from "../../../mui/Buttons/Buttons";
import { useDispatch, useSelector } from "react-redux";
import {
    getMentorRequest,
    requestApproval,
} from "../../../../redux/features/mentoringSlice";
import { useSnackbar } from "notistack";

export const ApproveRequest = ({ params }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { loading } = useSelector((state) => state.mentoring);
    const dispatch = useDispatch();
    const handleClick = () => {
        dispatch(requestApproval(params.mentoring_bookings_id)).then((res) => {
            if (res.payload.status === "failed") {
                enqueueSnackbar(res.payload.message, { variant: "error" });
            } else if (res.payload.status === "success") {
                dispatch(getMentorRequest());
                enqueueSnackbar(res.payload.message, {
                    variant: "success",
                });
            }
        });
    };
    return (
        <div>
            {params.status === 0 ? (
                <ApproveButton size="small" action={handleClick}>
                    {loading ? "Approving..." : "Approve"}
                </ApproveButton>
            ) : (
                ""
            )}
        </div>
    );
};
