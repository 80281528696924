import React from "react";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { Icon } from "@iconify/react";
import useResponsive from "../../../Hooks/useResponsive";
export default function ProfileDetails() {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const user = useSelector((state) => state.profile);
    return (
        <div>
            {smUp && (
                <Box>
                    <Stack direction={"row"}>
                        <Box sx={{ padding: 1 }}>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",
                                    minWidth: 450,
                                    height: 400,
                                    borderRadius: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 3,
                                        height: 30,
                                        backgroundColor: "#2f72b3",
                                        borderRadius: 10,
                                    }}
                                ></Box>
                                <Box sx={{ marginLeft: 1 }}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="clarity:email-outline-badged"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.email}
                                        </Typography>
                                    </Stack>
                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="cil:birthday-cake"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={1}>
                                            {user.dob}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="eva:phone-call-outline"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            +91 {user.mobileNo}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="ph:gender-intersex-bold"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.sex}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="bx:donate-blood"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.bloodGroup}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fluent-mdl2:family"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.maritalStatus}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                        {/* -------------------------------- */}
                        <Box sx={{ padding: 1 }}>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",
                                    minWidth: 450,
                                    height: 400,
                                    borderRadius: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 3,
                                        height: 30,
                                        backgroundColor: "#2f72b3",
                                        borderRadius: 10,
                                    }}
                                ></Box>
                                <Stack direction={"row"} marginLeft={5}>
                                    <Icon
                                        icon="akar-icons:location"
                                        width="35"
                                        height="35"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="h6">
                                        Address
                                    </Typography>
                                </Stack>
                                <Divider variant="middle" />
                                <Box sx={{ marginLeft: 1 }} paddingLeft={5}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Name of Parent :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.parentName}
                                        </Typography>
                                    </Stack>
                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            House Name :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.houseName}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Street / Area :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.PSTstreetOrArea}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            City / Town :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.city}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Post Office :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.PSTpostOffice}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            District :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.district}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            State :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.state}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Country :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.country}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Pincode :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.pinCode}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                        {/* ------------------------------------------------ */}
                    </Stack>
                </Box>
            )}
            {!smUp && (
                <Box>
                    <Stack direction={"column"}>
                        <Box sx={{ padding: 1 }}>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",
                                    height: 400,
                                    borderRadius: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 3,
                                        height: 30,
                                        backgroundColor: "#2f72b3",
                                        borderRadius: 10,
                                    }}
                                ></Box>
                                <Box sx={{ marginLeft: 1 }}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="clarity:email-outline-badged"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.email}
                                        </Typography>
                                    </Stack>
                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="cil:birthday-cake"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={1}>
                                            {user.dob}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="eva:phone-call-outline"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            +91 {user.mobileNo}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="ph:gender-intersex-bold"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.sex}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="bx:donate-blood"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.bloodGroup}
                                        </Typography>
                                    </Stack>

                                    <Divider variant="middle" />

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fluent-mdl2:family"
                                            width="30"
                                            height="30"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="h6" paddingTop={0}>
                                            {user.maritalStatus}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                        {/* -------------------------------- */}
                        <Box sx={{ padding: 1 }}>
                            <Paper
                                elevation={3}
                                sx={{
                                    width: "100%",
                                    height: 400,
                                    borderRadius: 3,
                                }}
                            >
                                <Box
                                    sx={{
                                        margin: 3,
                                        height: 30,
                                        backgroundColor: "#2f72b3",
                                        borderRadius: 10,
                                    }}
                                ></Box>
                                <Stack direction={"row"} marginLeft={5}>
                                    <Icon
                                        icon="akar-icons:location"
                                        width="35"
                                        height="35"
                                        color="#2f72b3"
                                    />
                                    <Typography variant="h6">
                                        Address
                                    </Typography>
                                </Stack>
                                <Divider variant="middle" />
                                <Box sx={{ marginLeft: 1 }} paddingLeft={2}>
                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Name of Parent :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.parentName}
                                        </Typography>
                                    </Stack>
                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            House Name :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.houseName}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Street / Area :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.PSTstreetOrArea}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            City / Town :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.city}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Post Office :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.PSTpostOffice}
                                        </Typography>
                                    </Stack>

                                    {/* <Divider variant="middle" /> */}

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            District :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.district}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            State :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.state}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Country :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.country}
                                        </Typography>
                                    </Stack>

                                    <Stack
                                        direction={"row"}
                                        spacing={1}
                                        sx={{ margin: 1 }}
                                    >
                                        <Icon
                                            icon="fa6-solid:arrows-to-dot"
                                            width="20"
                                            height="20"
                                            color="#2f72b3"
                                        />
                                        <Typography variant="subtitle2">
                                            Pincode :
                                        </Typography>
                                        <Typography
                                            variant="subtitle2"
                                            paddingTop={0}
                                        >
                                            {user.pinCode}
                                        </Typography>
                                    </Stack>
                                </Box>
                            </Paper>
                        </Box>
                        {/* ------------------------------------------------ */}
                    </Stack>
                </Box>
            )}
        </div>
    );
}
