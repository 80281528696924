import React from "react";
import GlobalDialog from "../../../../mui/Dialogue/GlobalDialog";
import { Button } from "@mui/material";
import { EditButton } from "../../../../mui/Buttons/Buttons";
import { EditPaymentInvoiceForm } from "./EditPaymentInvoiceForm";

export const EditPaymentInvoice = () => {
    const [open, setOpen] = React.useState(false);
    return (
        <div>
            <GlobalDialog
                title="Edit Payment Invoice"
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"sm"}
                closeButton={true}
                popupButton={
                    <EditButton
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        Edit
                    </EditButton>
                }
                content={<EditPaymentInvoiceForm />}
            />
        </div>
    );
};
