import { Button, Chip } from "@mui/material";
import { Icon } from "@iconify/react";
import { ApproveRequest } from "./ApproveRequest";
import { RejectRequest } from "./RejectRequest";

export const MENTORING_REQUEST_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "Sl No",
        width: 30,
    },
    {
        field: "user_name",
        headerName: "User Name",
        width: 150,
    },
    {
        field: "bookingDate",
        headerName: "Booking Date",
        width: 120,
    },

    {
        field: "from_time",
        headerName: "From Time",
        width: 120,
    },
    {
        field: "to_time",
        headerName: "To Time",
        width: 90,
    },

    {
        field: "purpose",
        headerName: "Purpose",
        width: 150,
    },
    {
        field: "remarks",
        headerName: "Remarks",
        width: 150,
    },
    {
        field: "status",
        headerName: "status",
        width: 150,
        renderCell: (params) => {
            if (params.row.expair_status === 0 && params.row.status === 1) {
                return (
                    <Chip
                        label="Meeting Over"
                        size="small"
                        color="primary"
                        variant="outlined"
                        icon={
                            <Icon
                                icon="fluent-mdl2:reminder-group"
                                size="small"
                                variant="outlined"
                                color="success"
                            />
                        }
                    />
                );
            } else if (params.row.expair_status === 0) {
                return (
                    <Chip
                        label="Expired"
                        variant="outlined"
                        color="error"
                        size="small"
                        icon={
                            <Icon
                                icon="radix-icons:cross-2"
                                variant="outlined"
                            />
                        }
                    />
                );
            } else {
                return (
                    <Chip
                        size="small"
                        variant="outlined"
                        icon={
                            params.row.status === 0 ? (
                                <Icon icon="mdi:account-pending-outline" />
                            ) : params.row.status === 1 ? (
                                <Icon icon="mdi:approve" />
                            ) : params.row.status === 2 ? (
                                <Icon icon="radix-icons:cross-2" />
                            ) : (
                                ""
                            )
                        }
                        color={
                            params.row.status === 0
                                ? "warning"
                                : params.row.status === 1
                                ? "success"
                                : params.row.status === 2
                                ? "error"
                                : ""
                        }
                        label={
                            params.row.status === 0
                                ? "Pending"
                                : params.row.status === 1
                                ? "Approved"
                                : params.row.status === 2
                                ? "Rejected"
                                : ""
                        }
                    />
                );
            }
        },
    },
    {
        field: "Approve",
        headerName: "Approve",
        width: 150,
        renderCell: (params) => {
            if (params.row.expair_status === 0) {
                return null;
            } else {
                return <ApproveRequest params={params.row} />;
            }
        },
    },
    {
        field: "Reject",
        headerName: "Reject",
        width: 150,
        renderCell: (params) => {
            if (params.row.expair_status === 0) {
                return null;
            } else {
                return <RejectRequest params={params.row} />;
            }
        },
    },
];
