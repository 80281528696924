import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import Page from "../../../../components/Page";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Container } from "@mui/system";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import { getBypassCount } from "../../../../redux/features/leaveSlice";
import { BYPASS_COUNT_TABLE_HEAD as columns } from "../../../../components/datagrid/leave/BypassCountTableHead";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";

const ReportingHeadBypassCount = () => {
    const [value, setValue] = useState(new Date());
    const [gridSize, setGridSize] = useState(5);

    const dispatch = useDispatch();
    const { leave } = useSelector((state) => state);

    const handleChange = (e) => {
        setValue(e);
        const formated = e.toISOString().replace("-", "/").split("-")[0];
        dispatch(getBypassCount(formated));
    };
    return (
        <Page>
            <Container component={"main"}>
                <Typography variant="h4">Bypass Count</Typography>
                <Breadcrumb />

                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            margin: "20px 0",
                        }}
                    >
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                views={["year", "month"]}
                                label="Year and Month"
                                inputFormat="YYYY/MM"
                                value={value}
                                onChange={handleChange}
                                disableFuture
                                renderInput={(params) => (
                                    <TextField {...params} variant="filled" />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                    <DataGrid
                        loading={leave.loading}
                        rows={leave.leaveBypassCounts}
                        columns={columns}
                        getRowId={(row) => row.reportingHeadId}
                        sx={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                        }}
                        pageSize={gridSize}
                        onPageSizeChange={(newGridSize) =>
                            setGridSize(newGridSize)
                        }
                        autoHeight
                        rowsPerPageOptions={[5, 10, 25, 50]}
                        components={{ Toolbar: CustomGridToolbar }}
                        componentsProps={{
                            toolbar: {
                                showQuickFilter: true,
                                quickFilterProps: { debounceMs: 500 },
                            },
                        }}
                    />
                </Box>
            </Container>
        </Page>
    );
};

export default ReportingHeadBypassCount;
