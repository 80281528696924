import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Box, Divider } from "@mui/material";
import { grey } from "@mui/material/colors";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GlobalDialog({
    content,
    actionButton,
    title,
    open,
    setOpen,
    popupButton,
    maxWidth,
    closeButton,
    fullWidth,
    closeCondition,
}) {
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason && reason == "backdropClick" && "escapeKeyDown") return;
        setOpen(false);
    };

    return (
        <div>
            <Box>{popupButton}</Box>
            <Dialog
                // onClick={(event) => event.stopPropagation()}
                // onClick={(event) => event.target = document.body}
                fullWidth={fullWidth}
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth={maxWidth}
            >
                <Box sx={{ backgroundColor: "grey.200" }}>
                    {closeButton === true ? (
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "flex-end",
                                margin: 0.5,
                            }}
                        >
                            <Button
                                size="small"
                                sx={{ borderRadius: 3 }}
                                variant="outlined"
                                onClick={() => setOpen(false)}
                            >
                                x
                            </Button>
                        </Box>
                    ) : null}

                    <DialogTitle>{title}</DialogTitle>
                </Box>
                <Divider />
                <DialogContent>
                    <Box>{content}</Box>
                </DialogContent>
                <DialogActions>{actionButton}</DialogActions>
            </Dialog>
        </div>
    );
}
