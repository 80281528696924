import { Icon } from "@iconify/react";
import {
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";
import { EditPaymentInvoice } from "../../../../components/datagrid/myworkspace/cro/PaymentInvoices/EditPaymentInvoice";
import { Delete } from "../../../../components/datagrid/myworkspace/cro/PaymentInvoices/Delete";
import { Link, useNavigate } from "react-router-dom";

export const Actions = () => {
    const navigate = useNavigate();
    return (
        <div>
            <Box padding={3}>
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Payment Link
                                        </Typography>
                                    </ListItemText>
                                    <Stack direction={"row"} spacing={1}>
                                        <Button>
                                            <Icon
                                                icon="logos:whatsapp-icon"
                                                width="25"
                                                height="25"
                                            />
                                        </Button>
                                        <Button>
                                            <Icon
                                                icon="fa-regular:copy"
                                                width="25"
                                                height="25"
                                            />
                                        </Button>
                                    </Stack>

                                    {/* <Notes /> */}
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Edit / Delete
                                        </Typography>
                                    </ListItemText>
                                    <Stack direction={"row"} spacing={1}>
                                        <EditPaymentInvoice />
                                        <Delete />
                                    </Stack>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>

                    <Grid item xs={12} lg={6} md={6}>
                        <Box
                            sx={{
                                backgroundColor: "#fff",
                                borderRadius: 3,
                            }}
                        >
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Icon
                                            icon="uil:arrow-up"
                                            rotate={1}
                                            color="#2f72b3"
                                            width="25"
                                            height="25"
                                        />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography
                                            fontWeight="bolder"
                                            fontSize={14}
                                        >
                                            Payment Invoice
                                        </Typography>
                                    </ListItemText>
                                    <Button variant="outlined">
                                        <Link
                                            to="/my-workspace/invoice"
                                            target="_blank"
                                        >
                                            Test
                                        </Link>
                                    </Button>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
};
