import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import GlobalSelectField from "../../../../mui/GlobalSelectField";
import { CancelButton, UpdateButton } from "../../../../mui/Buttons/Buttons";

export const EditPaymentInvoiceForm = () => {
    return (
        <div>
            <Box padding={3}>
                <Stack direction={"column"} spacing={2}>
                    <Box>
                        <TextField label="Student Name" disabled fullWidth />
                    </Box>
                    <Box>
                        <TextField label="Total Amount" disabled fullWidth />
                    </Box>
                    <Box>
                        <TextField label="Fees Paid " disabled fullWidth />
                    </Box>
                    <Box>
                        <TextField label="Balance Amount " disabled fullWidth />
                    </Box>
                    <Box>
                        <TextField label="Payment Amount " fullWidth />
                    </Box>
                    <Box>
                        <GlobalSelectField
                            options={{
                                // formik: formik,
                                label: "Admission Fee",
                                name: "courseId",
                                sx: { width: "100%" },
                                // data: data.coursesPreData,

                                keys: {
                                    id: "courseId",
                                    value: "courseName",
                                },
                            }}
                        />
                    </Box>
                </Stack>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: 2,
                    }}
                >
                    <Stack direction={"row"} spacing={2}>
                        <CancelButton>Cancel</CancelButton>
                        <UpdateButton>Update</UpdateButton>
                    </Stack>
                </Box>
            </Box>
        </div>
    );
};
