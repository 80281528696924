import React from "react";
import GlobalDialog from "./GlobalDialog";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import { CancelButton, DeleteButton } from "../Buttons/Buttons";

export default function GlobalDeleteDialog({
    api,
    loading,
    title,
    deleteNote,
    upDateCall,
    btnName,
    otherActions,
}) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <GlobalDialog
                title={title}
                open={open}
                setOpen={setOpen}
                fullWidth
                maxWidth={"xs"}
                popupButton={
                    <DeleteButton
                        size="small"
                        action={() => {
                            setOpen(true);
                        }}
                    >
                        {btnName === false ? "" : "Delete"}
                    </DeleteButton>
                }
                content={
                    <>
                        <Typography>{deleteNote}</Typography>
                    </>
                }
                actionButton={
                    <>
                        <CancelButton
                            action={() => {
                                setOpen(false);
                            }}
                        >
                            Cancel
                        </CancelButton>
                        <DeleteButton
                            action={() => {
                                dispatch(api).then((res) => {
                                    if (
                                        res.payload.status === "failed" ||
                                        res.payload.status === "error"
                                    ) {
                                        setOpen(false);
                                        enqueueSnackbar(res.payload.message, {
                                            variant: "error",
                                        });
                                    } else if (
                                        res.payload.status === "success"
                                    ) {
                                        dispatch(upDateCall);
                                        setOpen(false);
                                        otherActions(false);
                                        enqueueSnackbar(res.payload.message, {
                                            variant: "success",
                                        });
                                    }
                                });
                            }}
                        >
                            {loading ? "Deleting..." : "Delete"}
                        </DeleteButton>
                    </>
                }
            />
        </div>
    );
}
