export const PAYMENT_INVOICES_TABLE_HEAD = [
    {
        field: "id",
        headerName: "id",
        width: 150,
    },

    {
        field: "name",
        headerName: "name",
        width: 150,
    },
    {
        field: "paymentAmount",
        headerName: "paymentAmount",
        width: 150,
    },
    {
        field: "admissionFee",
        headerName: "admissionFee",
        width: 100,
    },
    {
        field: "invoiceGeneratedOn",
        headerName: "invoiceGeneratedOn",
        width: 200,
    },
    {
        field: "paymentLink",
        headerName: "paymentLink",
        width: 150,
    },

    {
        field: "paymentStatus",
        headerName: "paymentStatus",
        width: 150,
    },
    {
        field: "actions",
        headerName: "actions",
        width: 250,
    },

    {
        field: "transactionTimeStamp",
        headerName: "transactionTimeStamp",
        width: 150,
    },
    {
        field: "settlementTimeStamp",
        headerName: "settlementTimeStamp",
        width: 150,
    },
];
