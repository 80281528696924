export const EXPENSE_TABLE_HEAD = [
    {
        field: "serialNumber",
        headerName: "slNo",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "year",
        headerName: "Year",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "month",
        headerName: "Month",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "expenseType",
        headerName: "Expence Type",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Student Name",
        headerName: "StudentName",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "amount",
        headerName: "Amount",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Remarks",
        headerName: "remarks",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "Timestamp",
        headerName: "timestamp",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "a/cHodlder",
        headerName: "A/C Holder",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "ifsc",
        headerName: "IFSC",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "accoundNumber",
        headerName: "A/C Number",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "transactionDate",
        headerName: "Transaction Date",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "paymentMode",
        headerName: "Payment Mode",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "aprovalStatus",
        headerName: "Approval Status",
        headerAlign: "left",
        align: "left",
    },
    {
        field: "action",
        headerName: "Action",
        headerAlign: "left",
        align: "left",
    },
];
