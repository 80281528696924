import ToolTip from "../../../ToolTip/ToolTip";
export const OGA_REMARKS_TABLE_HEAD = [
  // { field: "id", headerName: "ID" },
  {
    field: "studentDetails",
    headerName: "studentDetails",
    width: 150,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.studentDetails}
          content={params.row.studentDetails}
        />
      );
    },
  },
  {
    field: "subjectSessions",
    headerName: "subjectSessions",
    width: 150,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.studentDetails}
          content={params.row.studentDetails}
        />
      );
    },
  },
  {
    field: "feePerSession",
    headerName: "Fee Per Session",
    width: 150,
  },
  {
    field: "sessionsPerWeek",
    headerName: "Sessions Per Week",
    width: 150,
  },
  {
    field: "timePrefered",
    headerName: "Time Prefered",
    width: 150,
    renderCell: (params) => {
      return (
        <ToolTip
          expand={params.row.timePrefered}
          content={params.row.timePrefered}
        />
      );
    },
  },
  {
    field: "trainerPrefered",
    headerName: "Trainer Prefered",
    width: 150,
  },
  {
    field: "totalFee",
    headerName: "Total Fee",
    width: 150,
  },
  {
    field: "feeStatus",
    headerName: "Fee Status",
    width: 150,
  },
];
