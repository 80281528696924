import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Attendance } from "../../views/user/EmployeeServices";
import { useDispatch, useSelector } from "react-redux";
import { checkAttendanceMarked } from "../../redux/features/AttendanceCheckSlice";

const AttendanceCheck = () => {
    const { isAttenanceMarked, loading } = useSelector(
        (state) => state.attendanceMarked
    );
    const dispatch = useDispatch();
    const location = useLocation();

    const handlePopUp = () => {
        window.alert("please Mark your Attendance");
    };
    useEffect(() => {
        dispatch(checkAttendanceMarked()).then((res) => {
            !res.payload.data.isAttenanceMarked && handlePopUp();
        });
    }, []);

    if (!loading) {
        return isAttenanceMarked ? <Outlet /> : <Attendance />;
    }
};

export default AttendanceCheck;
