import React from "react";
import { MarkAttendancePopup } from "./MarkAttendancePopup";
import { DeleteTrainerAttendance } from "./DeleteTrainerAttendance";
import { AddCourseEnrollmentDetailed } from "./AddCourseEnrollmentDetailed";
import { memo } from "react";
import { TpVerificationDetailed } from "./TpVerificationDetailed";
import { useCallback } from "react";
import { AcademicSessionsPopup } from "./StudentEnrollment/AcademicSessionsPopup";
import { SubjectEnrollmentPopup } from "./StudentEnrollment/SubjectEnrollmentPopup";

const AddAttendance = memo((params) => {
    return <MarkAttendancePopup params={params} />;
});

const TrainerAttendanceDelete = memo((params) => {
    return <DeleteTrainerAttendance params={params} />;
});
const AddEnrollmentDetails = memo((params) => {
    return <AddCourseEnrollmentDetailed params={params} />;
});
const TpVerificationDetail = memo((params) => {
    return <TpVerificationDetailed params={params.tpInvoiceId} />;
});

const AcademicSessionsPopupCheck = memo((params) => {
    return <AcademicSessionsPopup params={params} />;
});
const SubjectEnrollmentPopupView = memo((params) => {
    return <SubjectEnrollmentPopup params={params} />;
});

export {
    AddAttendance,
    TrainerAttendanceDelete,
    AddEnrollmentDetails,
    TpVerificationDetail,
    AcademicSessionsPopupCheck,
    SubjectEnrollmentPopupView,
};
