import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { HOD_LEAVE_APPLICATION_TABLE_HEAD } from "../../../../components/datagrid/leave/HodLeaveApplicationView";
import Page from "../../../../components/Page";
import { Icon } from "@iconify/react";
import {
    clearAllLeaveData,
    clearApplications,
    getLeaveAplictionsforHr,
} from "../../../../redux/features/leaveSlice";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import GlobalSelectField from "../../../../components/mui/GlobalSelectField";
import { useFormik } from "formik";
import useResponsive from "../../../../Hooks/useResponsive";
import {
    clearDeptPreData,
    getDepartmentPreData,
} from "../../../../redux/features/privilegesSlice";
import { padding } from "@mui/system";

const HrLeaveApplications = () => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const [gridSize, setGridSize] = useState(5);
    const [value, setValue] = useState(new Date());
    const [department, setDepartment] = useState([
        {
            departmentName: "All",
            departmentId: 0,
        },
    ]);

    const [filter, setFilter] = useState({
        date: `${new Date().getFullYear()}/${new Date().getMonth() + 1}`,
        departmentId: 0,
        status: 0,
    });

    const { deptPreData } = useSelector((state) => state.privileges);

    const dispatch = useDispatch();
    const { leave } = useSelector((state) => state);

    useEffect(() => {
        dispatch(getDepartmentPreData());
        dispatch(
            getLeaveAplictionsforHr(
                `${filter.date}/${filter.departmentId}/${filter.status}`
            )
        );
        return () => {
            dispatch(clearDeptPreData());
            dispatch(clearAllLeaveData());
        };
    }, []);

    const handleDate = (e) => {
        setValue(e);
        const date = `${new Date(e).getFullYear()}/${
            new Date(e).getMonth() + 1
        }`;

        setFilter({ ...filter, date: date });
    };

    useEffect(() => {}, [leave.applications]);

    const applyFilter = () => {
        dispatch(clearApplications());

        dispatch(
            getLeaveAplictionsforHr(
                `${filter.date}/${filter.departmentId}/${filter.status}`
            )
        );
    };
    useEffect(() => {}, [filter]);

    useEffect(() => {
        setDepartment([...department, ...deptPreData]);
    }, [deptPreData]);

    return (
        <div>
            <Page>
                <Container component="main">
                    <Typography variant="h4" sx={{ mb: 1 }}>
                        All Leave Applications
                    </Typography>
                    <Breadcrumb sx={{ paddingLeft: 0.3 }} />
                    {smUp && (
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: "grey.200",
                                padding: 2,
                                marginTop: 4,
                            }}
                        >
                            <Box sx={{ padding: 2, display: "flex", gap: 2 }}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        views={["year", "month"]}
                                        label="Year and Month"
                                        inputFormat="YYYY/MM"
                                        value={value}
                                        onChange={handleDate}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    flex: 1,
                                                }}
                                                {...params}
                                                helperText={null}
                                                variant="filled"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>

                                <FormControl
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <InputLabel id="dept">
                                        Department
                                    </InputLabel>
                                    <Select
                                        label="Department"
                                        value={filter.departmentId}
                                        onChange={({ target }) =>
                                            setFilter({
                                                ...filter,
                                                departmentId: target.value,
                                            })
                                        }
                                    >
                                        {department.map((dept, i) => (
                                            <MenuItem
                                                key={i}
                                                value={dept.departmentId}
                                            >
                                                {dept.departmentName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl
                                    sx={{
                                        flex: 1,
                                    }}
                                >
                                    <InputLabel>Status</InputLabel>
                                    <Select
                                        label="Status"
                                        value={filter.status}
                                        onChange={({ target }) =>
                                            setFilter({
                                                ...filter,
                                                status: target.value,
                                            })
                                        }
                                    >
                                        {[
                                            { id: 0, status: "All" },
                                            { id: 1, status: "Approved" },
                                            { id: 2, status: "Pending" },
                                            { id: 3, status: "Rejected" },
                                        ].map((item, i) => (
                                            <MenuItem key={i} value={item.id}>
                                                {item.status}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    onClick={applyFilter}
                                >
                                    {leave.loading ? (
                                        <Icon
                                            icon="eos-icons:bubble-loading"
                                            width="30"
                                            height="30"
                                        />
                                    ) : (
                                        "Apply Filter"
                                    )}
                                </Button>
                            </Box>
                        </Paper>
                    )}
                    {!smUp && (
                        <Paper
                            elevation={3}
                            sx={{
                                backgroundColor: "grey.200",
                                padding: 2,
                                marginTop: 4,
                            }}
                        >
                            <Box sx={{ padding: 1 }}>
                                <Stack direction={"column"} spacing={2}>
                                    <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                    >
                                        <DatePicker
                                            views={["year", "month"]}
                                            label="Year and Month"
                                            inputFormat="YYYY/MM"
                                            value={value}
                                            onChange={handleDate}
                                            renderInput={(params) => (
                                                <TextField
                                                    sx={{
                                                        flex: 1,
                                                    }}
                                                    {...params}
                                                    helperText={null}
                                                    variant="filled"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>

                                    <FormControl
                                        sx={{
                                            flex: 1,
                                        }}
                                    >
                                        <InputLabel id="dept">
                                            Department
                                        </InputLabel>
                                        <Select
                                            label="Department"
                                            fullWidth
                                            value={filter.departmentId}
                                            onChange={({ target }) =>
                                                setFilter({
                                                    ...filter,
                                                    departmentId: target.value,
                                                })
                                            }
                                        >
                                            {department.map((dept, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={dept.departmentId}
                                                >
                                                    {dept.departmentName}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl
                                        sx={{
                                            flex: 1,
                                        }}
                                    >
                                        <InputLabel>Status</InputLabel>
                                        <Select
                                            fullWidth
                                            label="Status"
                                            value={filter.status}
                                            onChange={({ target }) =>
                                                setFilter({
                                                    ...filter,
                                                    status: target.value,
                                                })
                                            }
                                        >
                                            {[
                                                { id: 0, status: "All" },
                                                { id: 1, status: "Approved" },
                                                { id: 2, status: "Pending" },
                                                { id: 3, status: "Rejected" },
                                            ].map((item, i) => (
                                                <MenuItem
                                                    key={i}
                                                    value={item.id}
                                                >
                                                    {item.status}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <Button
                                        variant="contained"
                                        onClick={applyFilter}
                                    >
                                        {leave.loading ? (
                                            <Icon
                                                icon="eos-icons:bubble-loading"
                                                width="30"
                                                height="30"
                                            />
                                        ) : (
                                            "Apply Filter"
                                        )}
                                    </Button>
                                </Stack>
                            </Box>
                        </Paper>
                    )}
                    <Paper elevation={3} sx={{ marginTop: 3 }}>
                        <DataGrid
                            loading={leave.loading}
                            rows={leave.applications}
                            columns={HOD_LEAVE_APPLICATION_TABLE_HEAD}
                            sx={{
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                            }}
                            pageSize={gridSize}
                            onPageSizeChange={(newGridSize) =>
                                setGridSize(newGridSize)
                            }
                            autoHeight
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            getRowId={(row) => row.leaveId}
                            components={{ Toolbar: CustomGridToolbar }}
                            componentsProps={{
                                toolbar: {
                                    showQuickFilter: true,
                                    quickFilterProps: { debounceMs: 500 },
                                },
                            }}
                        />
                    </Paper>
                </Container>
            </Page>
        </div>
    );
};

export default HrLeaveApplications;
