import React, { useEffect, useState } from "react";
import Wrapper from "../../../../components/Wrapper/Wrapper";
import { Box, Paper } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MENTORING_BOOKINGS_TABLE_HEAD } from "../../../../components/datagrid/EmployeeService/Mentoring/MentoringBookingTableHead";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { BookingPopUp } from "./BookingPopUp";
import { useSelector, useDispatch } from "react-redux";
import {
    getAllMentors,
    getUserBookings,
} from "../../../../redux/features/mentoringSlice";

export const MentoringBookings = () => {
    const dispatch = useDispatch();
    const { userBookings, loading } = useSelector((state) => state.mentoring);
    const [gridSize, setGridSize] = useState(5);

    useEffect(() => {
        dispatch(getUserBookings());
        dispatch(getAllMentors());
    }, []);

    return (
        <Wrapper
            title="Mentoring Bookings"
            actions={<BookingPopUp />}
            Content={
                <>
                    <Paper elevation={3}>
                        <Box sx={{ width: "100%", padding: 0 }}>
                            <DataGrid
                                components={{ Toolbar: CustomGridToolbar }}
                                loading={loading}
                                autoHeight
                                sx={{
                                    "& .MuiDataGrid-columnHeaderTitle": {
                                        textOverflow: "clip",
                                        whiteSpace: "break-spaces",
                                        lineHeight: 1,
                                    },
                                }}
                                // getRowHeight={() => "auto"}
                                rows={userBookings}
                                columns={MENTORING_BOOKINGS_TABLE_HEAD}
                                getRowId={(row) => row.serialNumber}
                                pageSize={gridSize}
                                rowsPerPageOptions={[5, 10, 25, 50]}
                                onPageSizeChange={(newGridSize) =>
                                    setGridSize(newGridSize)
                                }
                            />
                        </Box>
                    </Paper>
                </>
            }
        />
    );
};
