import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { DataGrid } from "@mui/x-data-grid";
import { MARKABSENTEES_TABLE_HEAD as columns } from "../../../../components/datagrid/Attendance/MarkAbsenteesTableHead";
import { Box, Paper } from "@mui/material";
import { Divider } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getDepartmentEmployees } from "../../../../redux/features/departmentSlice";
import { Icon } from "@iconify/react";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MarkAbsentees(attendances) {
    const dispatch = useDispatch();

    const employees = useSelector((state) => state.departments);
    const [open, setOpen] = React.useState(false);
    const [gridSize, setGridSize] = React.useState(5);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        dispatch(getDepartmentEmployees());
    }, []);

    useEffect(() => {}, []);

    return (
        <div>
            <Button
                sx={{ backgroundColor: "#ffe4de", color: "#c03530" }}
                onClick={handleClickOpen}
            >
                <Icon
                    icon="fluent:calendar-cancel-20-regular"
                    width="25"
                    height="25"
                />
                Mark Absentees
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <Box
                    sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        backgroundColor: "grey.200",
                    }}
                >
                    <DialogTitle>{"Mark Absentees..."}</DialogTitle>
                    <Button onClick={handleClose}>
                        <Icon icon="iconoir:cancel" width="30" height="30" />
                    </Button>
                </Box>

                <Divider sx={{ borderBottomWidth: 3 }} />
                <DialogContent>
                    <Box>
                        <Paper sx={{ width: 500 }}>
                            <Box sx={{ width: "100%" }}>
                                <DataGrid
                                    loading={employees.loading}
                                    rows={employees.emp}
                                    columns={columns}
                                    sx={{
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                    }}
                                    pageSize={gridSize}
                                    onPageSizeChange={(newGridSize) =>
                                        setGridSize(newGridSize)
                                    }
                                    autoHeight
                                    rowsPerPageOptions={[5, 10, 25, 50]}
                                    getRowId={(row) => row.userId}
                                    // components={{ Toolbar: CustomGridToolbar }}
                                    componentsProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                            quickFilterProps: {
                                                debounceMs: 500,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Paper>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    );
}
