import {
    Box,
    Collapse,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { role } from "./roleConfig";
import { styled, alpha, useTheme } from "@mui/material/styles";
import { navIcons, navIconsLG } from "../../utils/icons/icons";
import { NavLink, matchPath, useLocation } from "react-router-dom";

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    color: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
});

const ListItemStyle = styled((props) => (
    <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
    ...theme.typography.body2,
    height: 60,
    textTransform: "capitalize",
    color: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    marginBottom: 5,
}));

const SmCollapse = styled((props) => <Collapse {...props} />)(({ theme }) => ({
    position: "absolute",
}));

const SmallNavItem = ({ item, active }) => {
    const [open, setOpen] = useState(false);
    const theme = useTheme();
    const { icon, title, path, children } = item;
    const isActiveRoot = active(item.path);

    const activeRootStyle = {
        color: "primary.main",
        fontWeight: "fontWeightMedium",
        bgcolor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
        ),
    };

    const activeSubStyle = {
        color: "text.primary",
        fontWeight: "fontWeightMedium",
    };

    return (
        <div
            style={{
                position: "relative",
            }}
            onMouseEnter={() => {
                setOpen(true);
            }}
            onMouseLeave={() => setOpen(false)}
        >
            <ListItemStyle sx={{ ...(isActiveRoot && activeRootStyle) }}>
                <ListItemIconStyle>{icon}</ListItemIconStyle>
            </ListItemStyle>
            <SmCollapse in={open}>
                <List component="div" disablePadding>
                    {role.hod(children)?.map((item) => {
                        const { title, path } = item;

                        const isActiveSub = active(path);
                        return (
                            <ListItemStyle
                                key={title}
                                component={NavLink}
                                to={path || "#"}
                                sx={{
                                    ...(isActiveSub && activeSubStyle),
                                }}
                            >
                                <ListItemIconStyle>
                                    <Box
                                        component="span"
                                        sx={{
                                            width: 4,
                                            height: 4,
                                            display: "flex",
                                            borderRadius: "50%",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            bgcolor: "text.disabled",
                                            transition: (theme) =>
                                                theme.transitions.create(
                                                    "transform"
                                                ),
                                            ...(isActiveSub && {
                                                transform: "scale(2)",
                                                bgcolor: "primary.main",
                                            }),
                                        }}
                                    />
                                </ListItemIconStyle>
                                <ListItemText
                                    disableTypography
                                    primary={title}
                                />
                            </ListItemStyle>
                        );
                    })}
                </List>
            </SmCollapse>
        </div>
    );
};

const SmallNavSection = ({ navConfig }) => {
    console.log(navConfig);
    const { pathname } = useLocation();

    const match = (path) =>
        path ? !!matchPath({ path, end: false }, pathname) : false;

    return (
        <Box>
            <List disablePadding sx={{ p: 1 }}>
                {role.itemControl(navConfig)?.map((item) => (
                    <SmallNavItem
                        key={item.title}
                        item={{
                            ...item,
                            icon: navIconsLG[
                                item.name || item.title.replace(" ", "")
                            ],
                        }}
                        active={match}
                    />
                ))}
            </List>
        </Box>
    );
};

export default SmallNavSection;
