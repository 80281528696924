import React from "react";
import { Route, Routes } from "react-router-dom";
import Page404 from "../../views/user/Auth/Page404";

import {
    Attendance,
    EditLeaveApplication,
    LeaveApplication,
    MarkAttendance,
    ViewApplications,
    CabinBooking,
} from "../../views/user/EmployeeServices";
import { MentoringBookings } from "../../views/user/EmployeeServices/mentoring/MentoringBookings";

const EmployeeServiceRouter = () => {
    return (
        <Routes>
            <Route path="attendance" element={<Attendance />} />
            <Route path="leave" element={<LeaveApplication />} />
            <Route path="applications" element={<ViewApplications />} />
            <Route
                path="edit-leave-application/:id"
                element={<EditLeaveApplication />}
            />
            <Route path="markattendance" element={<MarkAttendance />} />
            <Route path="cabin-booking" element={<CabinBooking />} />
            <Route path="mentoring-bookings" element={<MentoringBookings />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};

export default EmployeeServiceRouter;
