import { Button, Box, Stack } from "@mui/material";
import React from "react";
import { Icon } from "@iconify/react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import Chip from "@mui/material/Chip";
import { Divider } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import useResponsive from "../../../../Hooks/useResponsive";
import { useDispatch, useSelector } from "react-redux";
import { OGA_REMARKS_TABLE_HEAD as columns } from "../../../../components/datagrid/CRM/CRM Leads/OgaRemarksTableHead";
import { getDetailedOgaRemarks } from "../../../../redux/features/crmSlice";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SroRemarks(params) {
  const data = useSelector((state) => state.crm);
  const dispatch = useDispatch();
  const smUp = useResponsive("up", "sm");
  const mdUp = useResponsive("up", "md");
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    dispatch(getDetailedOgaRemarks(params.params.croAssignmentId));

    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Button variant="contained" size="small" onClick={handleClickOpen}>
        View
        <Icon icon="ep:view" width="15" height="15" />
      </Button>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
      >
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="outlined"
            sx={{ borderRadius: 200 }}
          >
            <Icon icon="radix-icons:cross-2" width="18" height="18" />
          </Button>
        </DialogActions>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            letterSpacing: 1,
            fontWeight: "bolder",
            margin: 2,
          }}
        >
          <Stack direction={smUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
            <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
              <Chip
                label={`Name:- ${params.params.studentName}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="icon-park-outline:edit-name"
                    width="15"
                    height="15"
                  />
                }
              />
              <Chip
                label={`Contact:- ${params.params.contactNumber}`}
                color="primary"
                variant="outlined"
                icon={<Icon icon="bx:phone" width="15" height="15" />}
              />
            </Stack>
            <Stack direction={mdUp ? "row" : "column"} spacing={smUp ? 2 : 1}>
              <Chip
                label={`Class:- ${params.params.className}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="material-symbols:school-outline"
                    width="15"
                    height="15"
                  />
                }
              />

              <Chip
                label={`Course:- ${params.params.courseName}`}
                color="primary"
                variant="outlined"
                icon={
                  <Icon
                    icon="material-symbols:subject"
                    width="15"
                    height="15"
                  />
                }
              />
            </Stack>
          </Stack>
        </Box>
        <Divider />
        <Box sx={{ backgroundColor: "grey.200" }}>
          <DialogContent>
            <Box width={1300}>
              <Box sx={{ height: 200, width: "100%" }}>
                <DataGrid
                  loading={data.loading}
                  rows={[{ ...data.details.remarks, id: 1 }]}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5]}
                  getRowId={(row) => row.id}
                />
              </Box>
            </Box>
          </DialogContent>
        </Box>
      </Dialog>
    </div>
  );
}
