import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Avatar, Paper, Stack } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { clearProfile, getProfile } from "../../../redux/features/profileSlice";

import { Box, Container, margin } from "@mui/system";
import { Link } from "react-router-dom";
import Page from "../../../components/Page";
import Breadcrumb from "../../../components/breadcrumb/BreadCrumb";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Icon } from "@iconify/react";
import ProfileDetails from "./ProfileDetails";
import useResponsive from "../../../Hooks/useResponsive";
import Wrapper from "../../../components/Wrapper/Wrapper";
const Profile = () => {
    const smUp = useResponsive("up", "sm");
    const mdUp = useResponsive("up", "md");
    const [userDetail, setUserDetail] = useState({});

    const dispatch = useDispatch();
    const user = useSelector((state) => state.profile);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        dispatch(getProfile());
        return () => dispatch(clearProfile());
    }, []);
    useEffect(() => {
        setUserDetail(user);
    }, [user]);

    useEffect(() => {}, [user]);
    return (
        <>
            <Wrapper
                title="Profile"
                Content={
                    <>
                        <Page sx={{ backgroundColor: "#ffffff" }}>
                            {smUp && (
                                <>
                                    <Box sx={{ marginTop: 5 }}>
                                        <div>
                                            <Paper
                                                elevation={2}
                                                sx={{
                                                    width: "100%",
                                                    height: 220,
                                                    backgroundSize: "contain",
                                                    backgroundSize: "cover",
                                                    backgroundImage: `url(${"https://cdn.pixabay.com/photo/2015/11/19/08/52/banner-1050629_1280.jpg"})`,
                                                    borderTopRightRadius: 20,
                                                    borderTopLeftRadius: 20,
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}
                                            >
                                                <Box
                                                    paddingTop={14}
                                                    paddingLeft={5}
                                                >
                                                    <Stack direction={"row"}>
                                                        <Avatar
                                                            src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user.image}.jpg`}
                                                            sx={{
                                                                width: 130,
                                                                height: 130,
                                                                border: 2,
                                                                borderColor:
                                                                    "white",
                                                            }}
                                                            alt="photoURL"
                                                        />
                                                        <Box margin={4}>
                                                            <Typography
                                                                variant="h4"
                                                                sx={{
                                                                    mb: 1,
                                                                    color: "grey.200",
                                                                }}
                                                            >
                                                                {
                                                                    userDetail.name
                                                                }
                                                            </Typography>
                                                            <Stack
                                                                direction={
                                                                    "row"
                                                                }
                                                                spacing={1}
                                                            >
                                                                <Typography
                                                                    // variant="h6"
                                                                    color="#b6c5c2"
                                                                    textTransform="capitalize"
                                                                >
                                                                    {
                                                                        userDetail.suggestion
                                                                    }
                                                                </Typography>
                                                                {/* <Typography variant="body2" color="#b6c5c2" sx={{paddingTop:'2px'}}>
                            {userDetail.officialEmail}
                        </Typography>
                        <Typography variant="body2" color="#b6c5c2" sx={{paddingTop:'2px'}}>
                            +91 {userDetail.mobileNo}
                        </Typography> */}
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Paper>
                                            <Box
                                                sx={{
                                                    justifyContent: "flex-end",
                                                    backgroundColor: "grey.200",
                                                    borderRadius:
                                                        "0 0 15px 15px",
                                                    display: "flex",
                                                    height: 50,
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        id="basic-button"
                                                        aria-controls={
                                                            open
                                                                ? "basic-menu"
                                                                : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        aria-expanded={
                                                            open
                                                                ? "true"
                                                                : undefined
                                                        }
                                                        onClick={handleClick}
                                                    >
                                                        <Icon
                                                            icon="eva:menu-2-fill"
                                                            width="30"
                                                            height="30"
                                                            rotate={2}
                                                        />
                                                    </Button>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            "aria-labelledby":
                                                                "basic-button",
                                                        }}
                                                    >
                                                        <MenuItem>
                                                            <Icon
                                                                icon="akar-icons:edit"
                                                                width="30"
                                                                height="30"
                                                            />
                                                            <Link to="/menu/edit">
                                                                Edit Profile
                                                            </Link>
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            </Box>
                                            <ProfileDetails />
                                        </div>
                                    </Box>
                                </>
                            )}
                            {!smUp && (
                                <>
                                    <Box sx={{ marginTop: 5 }}>
                                        <div>
                                            <Paper
                                                elevation={2}
                                                sx={{
                                                    width: "100%",
                                                    height: 220,
                                                    backgroundSize: "contain",
                                                    backgroundSize: "cover",
                                                    backgroundImage: `url(${"https://i.pinimg.com/originals/c2/8b/4d/c28b4df094c5a0cf88391ea507cbeb3a.jpg"})`,
                                                    borderTopRightRadius: 20,
                                                    borderTopLeftRadius: 20,
                                                    borderBottomLeftRadius: 0,
                                                    borderBottomRightRadius: 0,
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "center",
                                                        paddingTop: 5,
                                                    }}
                                                >
                                                    <Stack direction={"column"}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                justifyContent:
                                                                    "center",
                                                            }}
                                                        >
                                                            <Avatar
                                                                src={`https://workspace.teaminterval.net/assets/employee/photo/passport_size/${user.image}.jpg`}
                                                                sx={{
                                                                    width: 80,
                                                                    height: 80,
                                                                    border: 2,
                                                                    borderColor:
                                                                        "white",
                                                                }}
                                                                alt="photoURL"
                                                            />
                                                        </Box>

                                                        <Box>
                                                            <Typography
                                                                variant="h6"
                                                                textAlign={
                                                                    "center"
                                                                }
                                                                sx={{
                                                                    mb: 1,
                                                                    color: "grey.200",
                                                                }}
                                                            >
                                                                {
                                                                    userDetail.name
                                                                }
                                                            </Typography>
                                                        </Box>

                                                        <Box>
                                                            <Typography
                                                                // variant="h6"
                                                                color="#b6c5c2"
                                                                textTransform="capitalize"
                                                                textAlign={
                                                                    "center"
                                                                }
                                                            >
                                                                {
                                                                    userDetail.suggestion
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </Paper>
                                            <Box
                                                sx={{
                                                    justifyContent: "flex-end",
                                                    backgroundColor: "grey.200",
                                                    borderRadius:
                                                        "0 0 15px 15px",
                                                    display: "flex",
                                                    height: 50,
                                                }}
                                            >
                                                <div>
                                                    <Button
                                                        id="basic-button"
                                                        aria-controls={
                                                            open
                                                                ? "basic-menu"
                                                                : undefined
                                                        }
                                                        aria-haspopup="true"
                                                        aria-expanded={
                                                            open
                                                                ? "true"
                                                                : undefined
                                                        }
                                                        onClick={handleClick}
                                                    >
                                                        <Icon
                                                            icon="eva:menu-2-fill"
                                                            width="30"
                                                            height="30"
                                                            rotate={2}
                                                        />
                                                    </Button>
                                                    <Menu
                                                        id="basic-menu"
                                                        anchorEl={anchorEl}
                                                        open={open}
                                                        onClose={handleClose}
                                                        MenuListProps={{
                                                            "aria-labelledby":
                                                                "basic-button",
                                                        }}
                                                    >
                                                        <MenuItem>
                                                            <Icon
                                                                icon="akar-icons:edit"
                                                                width="30"
                                                                height="30"
                                                            />
                                                            <Link to="/menu/edit">
                                                                Edit Profile
                                                            </Link>
                                                        </MenuItem>
                                                    </Menu>
                                                </div>
                                            </Box>
                                            <ProfileDetails />
                                        </div>
                                    </Box>
                                </>
                            )}
                        </Page>
                    </>
                }
            />
        </>
    );
};

export default Profile;
