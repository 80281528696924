// import * as React from "react";
// import Button from "@mui/material/Button";
// import Dialog from "@mui/material/Dialog";
// import DialogActions from "@mui/material/DialogActions";
// import DialogContent from "@mui/material/DialogContent";
// import DialogTitle from "@mui/material/DialogTitle";
// import Slide from "@mui/material/Slide";
// import { Icon } from "@iconify/react";
// import { Paper, Box, Stack, Typography, Divider } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
// import { UPDATE_ATTENDANCE_HEAD as columns } from "../../../components/datagrid/Attendance/UpdateAttendanceTableHead";
// import CustomGridToolbar from "../../../components/datagrid/GridToolBar";
// import { Avatar } from "@mui/material";

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

// export default function UpdateAttendanceDialogue(params) {
//   //
//   const [open, setOpen] = React.useState(false);
//   const [gridSize, setGridSize] = React.useState(50);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   return (
//     <div>
//       <Button
//         onClick={handleClickOpen}
//         size="medium"
//         sx={{ backgroundColor: "#dccfe0", color: "#7e4d8b" }}
//       >
//         <Icon
//           icon="material-symbols:edit-document-outline-sharp"
//           width="20"
//           height="20"
//         />
//         Edit
//       </Button>
//       <Dialog
//         open={open}
//         TransitionComponent={Transition}
//         keepMounted
//         onClose={handleClose}
//         aria-describedby="alert-dialog-slide-description"
//       >
//         <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
//           {" "}
//           <Button onClick={handleClose}>
//             <Icon icon="iconoir:cancel" width="30" height="30" />
//           </Button>
//         </Box>

//         <DialogTitle>
//           {"Upadate Attendance"}
//           <Divider sx={{ borderBottomWidth: 3 }} />

//         </DialogTitle>

//         <DialogContent>
//           <Box>
//             <Paper sx={{ width: 500 }}>
//               {/* <Box sx={{ width: "100%" }}>
//                 <DataGrid
//                   loading={params.params.loading}
//                   rows={params.params.row.attendance}
//                   columns={columns}
//                   sx={{
//                     borderTopLeftRadius: 0,
//                     borderTopRightRadius: 0,
//                   }}
//                   pageSize={gridSize}
//                   onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
//                   autoHeight
//                   rowsPerPageOptions={[5, 10, 25, 50]}
//                   getRowId={(row) => row.id}
//                   components={{ Toolbar: CustomGridToolbar }}
//                   componentsProps={{
//                     toolbar: {
//                       showQuickFilter: true,
//                       quickFilterProps: { debounceMs: 500 },
//                     },
//                   }}
//                 />
//               </Box> */}
//             </Paper>
//           </Box>
//         </DialogContent>
//       </Dialog>
//     </div>
//   );
// }
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { Button } from "@mui/material";

export default function UpdateAttendanceDialogue(params) {
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/my-tasks/edit-attendance/${params.params.row.userId}`, {
            state: params.params.row,
        });
    };
    return (
        <div>
            <Button
                onClick={handleNavigate}
                sx={{ backgroundColor: "#dccfe0", color: "#7e4d8b" }}
                disabled={params.params.isEditable === false ? true : false}
            >
                <Icon
                    icon="material-symbols:edit-note"
                    width="25"
                    height="25"
                />
                edit
            </Button>
        </div>
    );
}
