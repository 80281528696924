import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiRequest from "../../api/request";
import { formatDataForDataTable } from "../../utils/formatData";

const initialState = {
    loading: false,
    projects: [],
    projectClasses: [],
    projectsPreData: [],
    projectPackages: [],
    projectCategories: [],
    packageSubjectSessions: [],
    projectPackagesPreData: [],
    classPreData: [],
    subjectsPreData: [],
    miscMaster: [],
    packageClasses: [],
    coursesBaseTable: [],
    textPublisher: [],
    courseAvailable: [],
};

export const getProjects = createAsyncThunk("cro/getProjects", async () => {
    const res = await apiRequest({
        url: `masters/getProjects`,
        method: "get",
    });

    return res;
});

export const projectStatus = createAsyncThunk(
    "cro/projectStatus",
    async (projectId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectStatus/${projectId}`,
            method: "patch",
        });
        console.log(res);
        return res;
    }
);
export const projectListingStatus = createAsyncThunk(
    "cro/projectListingStatus",
    async (projectId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectListing/${projectId}`,
            method: "patch",
        });
        console.log(res);
        return res;
    }
);

export const deleteProject = createAsyncThunk(
    "cro/deleteProject",
    async (projectId) => {
        const res = await apiRequest({
            url: `masters/deleteProject/${projectId}`,
            method: "delete",
        });
        console.log(res);
        return res;
    }
);

export const getProjectClasses = createAsyncThunk(
    "cro/getProjectClasses",
    async () => {
        const res = await apiRequest({
            url: `masters/getClasses`,
            method: "get",
        });
        console.log(res);

        return res;
    }
);

export const addProject = createAsyncThunk("cro/addProject", async (data) => {
    const res = await apiRequest({
        url: `masters/addProject`,
        method: "post",
        data: data,
    });
    return res;
});

export const editProject = createAsyncThunk("cro/editProject", async (data) => {
    const res = await apiRequest({
        url: `masters/editProject/${data.projectId}`,
        method: "put",
        data: data,
    });
    return res;
});

export const projectClassStatus = createAsyncThunk(
    "cro/projectClassStatus",
    async (classId) => {
        const res = await apiRequest({
            url: `masters/toggleClassStatus/${classId}`,
            method: "patch",
        });
        return res;
    }
);

export const projectClassListingStatus = createAsyncThunk(
    "cro/projectClassListingStatus",
    async (classId) => {
        const res = await apiRequest({
            url: `masters/toggleClassListing/${classId}`,
            method: "patch",
        });
        return res;
    }
);
export const addPrjectClass = createAsyncThunk(
    "cro/addPrjectClass",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addClass`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const getProjectsPreData = createAsyncThunk(
    "cro/getProjectsPreData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getProjects`,
            method: "get",
        });

        return res;
    }
);
export const deleteProjectClass = createAsyncThunk(
    "cro/deleteProjectClass",
    async (classId) => {
        const res = await apiRequest({
            url: `masters/deleteClass/${classId}`,
            method: "delete",
        });
        return res;
    }
);
export const editProjectClass = createAsyncThunk(
    "cro/editProjectClass",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editClass/${data.classId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);
export const getProjectPackages = createAsyncThunk(
    "cro/getProjectPackages",
    async () => {
        const res = await apiRequest({
            url: `masters/getProjectPackages`,
            method: "get",
        });

        return res;
    }
);

export const projectPackageStatus = createAsyncThunk(
    "cro/projectPackageStatus",
    async (packageId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectPackageStatus/${packageId}`,
            method: "patch",
        });
        return res;
    }
);

export const projectPackageListingStatus = createAsyncThunk(
    "cro/projectPackageListingStatus",
    async (packageId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectPackageListing/${packageId}`,
            method: "patch",
        });
        return res;
    }
);

export const addProjectPackage = createAsyncThunk(
    "cro/addProjectPackage",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addProjectPackage`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const editProjectPackage = createAsyncThunk(
    "cro/editProjectPackage",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editProjectPackage/${data.packageId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const deleteProjectPackage = createAsyncThunk(
    "cro/deleteProjectPackage",
    async (packageId) => {
        const res = await apiRequest({
            url: `masters/deleteProjectPackage/${packageId}`,
            method: "delete",
        });
        return res;
    }
);
export const getProjectCategories = createAsyncThunk(
    "cro/getProjectCategories",
    async () => {
        const res = await apiRequest({
            url: `masters/getProjectCategories`,
            method: "get",
        });
        return res;
    }
);
export const addCategories = createAsyncThunk(
    "cro/addCategories",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addProjectCategory`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const editCategories = createAsyncThunk(
    "cro/editCategories",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editProjectCategory/${data.categoryId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);
export const deleteCategories = createAsyncThunk(
    "cro/deleteCategories",
    async (categoryId) => {
        const res = await apiRequest({
            url: `masters/deleteProjectCategory/${categoryId}`,
            method: "delete",
        });
        return res;
    }
);
export const projectCategoriesListingStatus = createAsyncThunk(
    "cro/projectCategoriesListingStatus",
    async (categoryId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectCategoryListing/${categoryId}`,
            method: "patch",
        });
        return res;
    }
);

export const projectCategoriesStatus = createAsyncThunk(
    "cro/projectCategoriesStatus",
    async (categoryId) => {
        const res = await apiRequest({
            url: `masters/toggleProjectCategoryStatus/${categoryId}`,
            method: "patch",
        });
        return res;
    }
);
export const getPackageSubjectSessions = createAsyncThunk(
    "cro/getPackageSubjectSessions",
    async () => {
        const res = await apiRequest({
            url: `masters/getPackageSubjectSessions`,
            method: "get",
        });
        return res;
    }
);

export const addPackageSubjectSessions = createAsyncThunk(
    "cro/addPackageSubjectSessions",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addPackageSubjectSession`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const editPackageSubjectSessions = createAsyncThunk(
    "cro/editPackageSubjectSessions",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editPackageSubjectSession/${data.packageSubjectSessionId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const deletePackageSubjectSessions = createAsyncThunk(
    "cro/deletePackageSubjectSessions",
    async (packageSubjectSessionId) => {
        const res = await apiRequest({
            url: `masters/deletePackageSubjectSession/${packageSubjectSessionId}`,
            method: "delete",
        });
        return res;
    }
);
export const packageSubjectSessionListing = createAsyncThunk(
    "cro/packageSubjectSessionListing",
    async (packageSubjectSessionId) => {
        const res = await apiRequest({
            url: `masters/togglePackageSubjectSessionListing/${packageSubjectSessionId}`,
            method: "patch",
        });
        return res;
    }
);

export const packageSubjectSessionStatus = createAsyncThunk(
    "cro/packageSubjectSessionStatus",
    async (packageSubjectSessionId) => {
        const res = await apiRequest({
            url: `masters/togglePackageSubjectSessionStatus/${packageSubjectSessionId}`,
            method: "patch",
        });
        return res;
    }
);

export const getProjectPackagesPreData = createAsyncThunk(
    "cro/getProjectPackagesPreData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getProjectPackages`,
            method: "get",
        });
        return res;
    }
);

export const getClassPreData = createAsyncThunk(
    "cro/getClassPreData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getClasses`,
            method: "get",
        });
        return res;
    }
);

export const getSubjectsPreData = createAsyncThunk(
    "cro/getSubjectsPreData",
    async () => {
        const res = await apiRequest({
            url: `cr-pre-data/getSubjects`,
            method: "get",
        });
        return res;
    }
);

export const getMiscMaster = createAsyncThunk("cro/getMiscMaster", async () => {
    const res = await apiRequest({
        url: `masters/getMiscMasters`,
        method: "get",
    });
    return res;
});

export const addMiscMaster = createAsyncThunk(
    "cro/addMiscMaster",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addMiscMaster`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const editMiscMaster = createAsyncThunk(
    "cro/editMiscMaster",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editMiscMaster/${data.miscMasterId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const deleteMiscMaster = createAsyncThunk(
    "cro/deleteMiscMaster",
    async (miscMasterId) => {
        const res = await apiRequest({
            url: `masters/deleteMiscMaster/${miscMasterId}`,
            method: "delete",
        });
        return res;
    }
);

export const miscMasterListing = createAsyncThunk(
    "cro/miscMasterListing",
    async (miscMasterId) => {
        const res = await apiRequest({
            url: `masters/toggleMiscMasterListing/${miscMasterId}`,
            method: "patch",
        });
        return res;
    }
);

export const miscMasterStatus = createAsyncThunk(
    "cro/miscMasterStatus",
    async (miscMasterId) => {
        const res = await apiRequest({
            url: `masters/toggleMiscMasterStatus/${miscMasterId}`,
            method: "patch",
        });
        return res;
    }
);
export const getPackageClasses = createAsyncThunk(
    "cro/getPackageClasses",
    async () => {
        const res = await apiRequest({
            url: `masters/getPackageClasses`,
            method: "get",
        });
        return res;
    }
);

export const addPackageClasses = createAsyncThunk(
    "cro/addPackageClasses",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addPackageClass`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const editPackageClasses = createAsyncThunk(
    "cro/editPackageClasses",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editPackageClass/${data.packageClassId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const deletePackageClasses = createAsyncThunk(
    "cro/deletePackageClasses",
    async (packageClassId) => {
        const res = await apiRequest({
            url: `masters/deletePackageClass/${packageClassId}`,
            method: "delete",
        });
        return res;
    }
);

export const packageClassListing = createAsyncThunk(
    "cro/packageClassListing",
    async (packageClassId) => {
        const res = await apiRequest({
            url: `masters/togglePackageClassListing/${packageClassId}`,
            method: "patch",
        });
        return res;
    }
);
export const packageClassStatus = createAsyncThunk(
    "cro/packageClassStatus",
    async (packageClassId) => {
        const res = await apiRequest({
            url: `masters/togglePackageClassStatus/${packageClassId}`,
            method: "patch",
        });
        return res;
    }
);

export const getCoursesBaseTable = createAsyncThunk(
    "cro/getCoursesBaseTable",
    async () => {
        const res = await apiRequest({
            url: `research/getBaseCoursesListing`,
            method: "get",
        });
        return res;
    }
);
export const getTextPublisher = createAsyncThunk(
    "cro/getTextPublisher",
    async () => {
        const res = await apiRequest({
            url: `masters/getTextPublisher`,
            method: "get",
        });
        return res;
    }
);

export const addTextPublisher = createAsyncThunk(
    "cro/addTextPublisher",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addTextPublisher`,
            method: "post",
            data: data,
        });
        return res;
    }
);
export const editTextPublisher = createAsyncThunk(
    "cro/editTextPublisher",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editTextPublisher/${data.textPublisherId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);

export const deleteTextPublisher = createAsyncThunk(
    "cro/deleteTextPublisher",
    async (textPublisheId) => {
        const res = await apiRequest({
            url: `masters/deleteTextPublisher/${textPublisheId}`,
            method: "delete",
        });
        return res;
    }
);

export const textPublisherListing = createAsyncThunk(
    "cro/textPublisherListing",
    async (textPublisherId) => {
        const res = await apiRequest({
            url: `masters/toggleTextPublisherListing/${textPublisherId}`,
            method: "patch",
        });
        return res;
    }
);
export const textPublisherStatus = createAsyncThunk(
    "cro/textPublisherStatus",
    async (textPublisherId) => {
        const res = await apiRequest({
            url: `masters/toggleTextPublisherStatus/${textPublisherId}`,
            method: "patch",
        });
        return res;
    }
);

export const getCourseAvailable = createAsyncThunk(
    "cro/getCourseAvailable",
    async () => {
        const res = await apiRequest({
            url: `masters/getCourseAvailable`,
            method: "get",
        });
        return res;
    }
);

export const addCourseAvailable = createAsyncThunk(
    "cro/addCourseAvailable",
    async (data) => {
        const res = await apiRequest({
            url: `masters/addCourseAvailable`,
            method: "post",
            data: data,
        });
        return res;
    }
);

export const editCourseAvailable = createAsyncThunk(
    "cro/editCourseAvailable",
    async (data) => {
        const res = await apiRequest({
            url: `masters/editCourseAvailable/${data.courseAvailableId}`,
            method: "put",
            data: data,
        });
        return res;
    }
);
export const deleteCourseAvailable = createAsyncThunk(
    "cro/deleteCourseAvailable",
    async (courseAvailableId) => {
        const res = await apiRequest({
            url: `masters/deleteCourseAvailable/${courseAvailableId}`,
            method: "delete",
        });
        return res;
    }
);

export const courseAvailableListing = createAsyncThunk(
    "cro/courseAvailableListing",
    async (courseAvailableId) => {
        const res = await apiRequest({
            url: `masters/toggleCourseAvailableListing/${courseAvailableId}`,
            method: "patch",
        });
        return res;
    }
);

export const courseAvailableStatus = createAsyncThunk(
    "cro/courseAvailableStatus",
    async (courseAvailableId) => {
        const res = await apiRequest({
            url: `masters/toggleCourseAvailableStatus/${courseAvailableId}`,
            method: "patch",
        });
        return res;
    }
);
const mastersSlice = createSlice({
    name: "masters",
    initialState: initialState,

    extraReducers: (builder) => {
        // -----------------------------getProjects----------------------------------
        builder.addCase(getProjects.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjects.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.projects);
            return {
                ...state,
                projects: data,

                loading: false,
            };
        });
        // ---------------------projectStatus------------------------------------
        builder.addCase(projectStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(projectStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------projectListingStatus-----------------------------------
        builder.addCase(projectListingStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(projectListingStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------------deleteProject-----------------------------]
        builder.addCase(deleteProject.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteProject.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------------getProjectClasses----------------------
        builder.addCase(getProjectClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjectClasses.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.classes);
            return {
                ...state,
                projectClasses: data,

                loading: false,
            };
        });
        // ---------------------------addProject---------------------------
        builder.addCase(addProject.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addProject.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------------editProject------------------------
        builder.addCase(editProject.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editProject.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -----------------------projectClassStatus---------------------------
        builder.addCase(projectClassStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(projectClassStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------projectClassListingStatus-----------------------
        builder.addCase(projectClassListingStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            projectClassListingStatus.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ---------------addPrjectClass----------------------------------
        builder.addCase(addPrjectClass.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addPrjectClass.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------------------getProjectsPreData----------------------
        builder.addCase(getProjectsPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjectsPreData.fulfilled, (state, action) => {
            const data = action.payload.data.projects;
            return {
                ...state,
                projectsPreData: data,

                loading: false,
            };
        });
        // -------------------deleteProjectClass------------------------------------
        builder.addCase(deleteProjectClass.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteProjectClass.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------------editProjectClass-----------------------------------

        builder.addCase(editProjectClass.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editProjectClass.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -----------------------getProjectPackeges----------------------------
        builder.addCase(getProjectPackages.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjectPackages.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data.packages);
            return {
                ...state,
                projectPackages: data,

                loading: false,
            };
        });
        // -------------projectPackageStatus-----------------

        builder.addCase(projectPackageStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(projectPackageStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------projectPackageListingStatus----------------------------------

        builder.addCase(
            projectPackageListingStatus.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            projectPackageListingStatus.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // -----------------------addProjectPackage-----------------------

        builder.addCase(addProjectPackage.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addProjectPackage.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -----------------------editProjectPackage--------------------------------

        builder.addCase(editProjectPackage.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editProjectPackage.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------deleteProjectPackage--------------------------
        builder.addCase(deleteProjectPackage.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteProjectPackage.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------getProjectCategories------------------------------------
        builder.addCase(getProjectCategories.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getProjectCategories.fulfilled, (state, action) => {
            const data = action.payload.data.categories;
            return {
                ...state,
                projectCategories: data,

                loading: false,
            };
        });
        // ------------------------add-Categories-------------------------------
        builder.addCase(addCategories.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addCategories.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------editCategories--------------------------------
        builder.addCase(editCategories.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editCategories.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------deleteCategories-------------------------------------
        builder.addCase(deleteCategories.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteCategories.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------------------------projectCategoriesListingStatus----------------
        builder.addCase(
            projectCategoriesListingStatus.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            projectCategoriesListingStatus.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // -----------------------------projectCategoriesStatus------------------------

        builder.addCase(projectCategoriesStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(projectCategoriesStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ------------------------------getPackageSubjectSessions-------------------------------

        builder.addCase(getPackageSubjectSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getPackageSubjectSessions.fulfilled,
            (state, action) => {
                const data = formatDataForDataTable(
                    action.payload.data.packageSubjectSessions
                );
                return {
                    ...state,
                    packageSubjectSessions: data,

                    loading: false,
                };
            }
        );
        // -------------------addPackageSubjectSessions---------------------
        builder.addCase(addPackageSubjectSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            addPackageSubjectSessions.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ------------------------editPackageSubjectSessions---------------------------
        builder.addCase(editPackageSubjectSessions.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            editPackageSubjectSessions.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // -------------------------deletePackageSubjectSessions----------------------

        builder.addCase(
            deletePackageSubjectSessions.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            deletePackageSubjectSessions.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // -----------------------packageSubjectSessionListing------------------------
        builder.addCase(
            packageSubjectSessionListing.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            packageSubjectSessionListing.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // ---------------------packageSubjectSessionStatus---------------------------------
        builder.addCase(
            packageSubjectSessionStatus.pending,
            (state, action) => {
                return {
                    ...state,
                    loading: true,
                };
            }
        );
        builder.addCase(
            packageSubjectSessionStatus.fulfilled,
            (state, action) => {
                return {
                    ...state,

                    loading: false,
                };
            }
        );
        // --------------------------getProjectPackagesPreData---------------------------

        builder.addCase(getProjectPackagesPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(
            getProjectPackagesPreData.fulfilled,
            (state, action) => {
                const data = action.payload.data.packages;

                return {
                    ...state,
                    projectPackagesPreData: data,
                    loading: false,
                };
            }
        );
        // --------------------getClassPreData-----------------------------------
        builder.addCase(getClassPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getClassPreData.fulfilled, (state, action) => {
            const data = action.payload.data;

            return {
                ...state,
                classPreData: data,
                loading: false,
            };
        });
        // ------------------------getSubjectsPreData---------------------------------
        // --------------------getClassPreData-----------------------------------
        builder.addCase(getSubjectsPreData.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getSubjectsPreData.fulfilled, (state, action) => {
            const data = action.payload.data;
            console.log("data", data);
            return {
                ...state,
                subjectsPreData: data,
                loading: false,
            };
        });
        // ----------------getmiscMaster-----------------------------

        builder.addCase(getMiscMaster.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getMiscMaster.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.miscMasters
            );
            console.log("data", data);
            return {
                ...state,
                miscMaster: data,
                loading: false,
            };
        });
        // -------------------addMiscMaster------------------------------
        builder.addCase(addMiscMaster.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addMiscMaster.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------editMiscMaster---------------------------------

        builder.addCase(editMiscMaster.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editMiscMaster.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ----------------------deleteMiscMaster---------------------------
        builder.addCase(deleteMiscMaster.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteMiscMaster.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------miscMasterListing--------------------------

        builder.addCase(miscMasterListing.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(miscMasterListing.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------miscMasterStatus---------------------------------
        builder.addCase(miscMasterStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(miscMasterStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------getPackageClasses-----------------------------

        builder.addCase(getPackageClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getPackageClasses.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.packageClasses
            );
            return {
                ...state,
                packageClasses: data,

                loading: false,
            };
        });
        // ------------------------addPackageClasses------------------------------

        builder.addCase(addPackageClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addPackageClasses.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------editPackageClasses------------------------------

        builder.addCase(editPackageClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editPackageClasses.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------deletePackageClasses--------------------------------

        builder.addCase(deletePackageClasses.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deletePackageClasses.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -----------------------packageClassListing-------------------------
        builder.addCase(packageClassListing.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(packageClassListing.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------packageClassStatus--------------------------------
        builder.addCase(packageClassStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(packageClassStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ------------------------getCoursesBaseTable------------------------------------
        builder.addCase(getCoursesBaseTable.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCoursesBaseTable.fulfilled, (state, action) => {
            const data = formatDataForDataTable(action.payload.data);

            return {
                ...state,
                coursesBaseTable: data,

                loading: false,
            };
        });
        // ---------------------getTextPublisher------------------------------------------
        builder.addCase(getTextPublisher.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getTextPublisher.fulfilled, (state, action) => {
            const data = formatDataForDataTable(
                action.payload.data.textPublisher
            );
            return {
                ...state,
                textPublisher: data,

                loading: false,
            };
        });
        // ---------------addTextPublisher---------------------------------------
        builder.addCase(addTextPublisher.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addTextPublisher.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------------editTextPublisher-----------------------------------
        builder.addCase(editTextPublisher.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editTextPublisher.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ---------------------------deleteTextPublisher--------------------------------
        builder.addCase(deleteTextPublisher.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteTextPublisher.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // --------------------------textPublisherListing---------------------

        builder.addCase(textPublisherListing.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(textPublisherListing.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -----------------------------textPublisherStatus------------------------------
        builder.addCase(textPublisherStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(textPublisherStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // -------------------getCourseAvailable-----------------------------------

        builder.addCase(getCourseAvailable.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(getCourseAvailable.fulfilled, (state, action) => {
            const data = action.payload.data.coursesAvailable;

            return {
                ...state,
                courseAvailable: data,

                loading: false,
            };
        });
        // -------------------------addCourseAvailable----------------------------
        builder.addCase(addCourseAvailable.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(addCourseAvailable.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ------------------------------editCourseAvailable--------------------------------
        builder.addCase(editCourseAvailable.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(editCourseAvailable.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ------------------------------deleteCourseAvailable---------------------------------
        builder.addCase(deleteCourseAvailable.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(deleteCourseAvailable.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ----------------------------courseAvailableListing------------------------------
        builder.addCase(courseAvailableListing.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(courseAvailableListing.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
        // ------------------------------------------courseAvailableStatus-----------------------
        builder.addCase(courseAvailableStatus.pending, (state, action) => {
            return {
                ...state,
                loading: true,
            };
        });
        builder.addCase(courseAvailableStatus.fulfilled, (state, action) => {
            return {
                ...state,

                loading: false,
            };
        });
    },
});

export default mastersSlice.reducer;
