import { Container, Typography, Box } from "@mui/material";
import React from "react";
import Breadcrumb from "../../../../components/breadcrumb/BreadCrumb";
import Page from "../../../../components/Page";
import { DataGrid } from "@mui/x-data-grid";
import CustomGridToolbar from "../../../../components/datagrid/GridToolBar";
import { useState } from "react";
import {
  getReportingHeadAttendance,
  createTableHead,
} from "../../../../redux/features/attendanceSlice";
import { Paper, TextField } from "@mui/material";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MarkAttendanceStyle from "../../../../components/datagrid/MarkAttendanceStyle";
import {
  END_PORTION_TABLE_HEAD,
  START_PORTION_TABLE_HEAD,
} from "../../../../components/datagrid/Attendance/ReportingHeadAttendanceTableHead";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MarkAbsentees from "./MarkAbsentees";
import Wrapper from "../../../../components/Wrapper/Wrapper";

export default function ReportingHeadAttendance() {
  const [value, setValue] = React.useState("");
  const [date, setDate] = React.useState();
  const attendances = useSelector((state) => state.attendance);
  const [tableHead, setTableHead] = useState([]);
  const dispatch = useDispatch();
  const [gridSize, setGridSize] = useState(5);

  // useEffect(() => {
  //   dispatch(createTableHead("2022/3"));
  // }, []);

  useEffect(() => {
    const table = attendances.tableHead.map((item, i) => {
      if (item.day) {
        item = {
          ...item,
          renderCell: (params) => MarkAttendanceStyle(params),
        };
      }
      return item;
    });

    setTableHead([
      ...START_PORTION_TABLE_HEAD,
      ...table,
      ...END_PORTION_TABLE_HEAD,
    ]);
  }, [attendances.tableHead]);

  useEffect(() => {
    let newValue = new Date();
    setValue(newValue);
    const yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
      newValue.toISOString().split("T")[0].split("-")[1]
    }`;

    dispatch(getReportingHeadAttendance(yer));
  }, []);

  const handleChange = (newValue) => {
    setValue(newValue);
    let yer = `${newValue.toISOString().split("T")[0].split("-")[0]}/${
      newValue.toISOString().split("T")[0].split("-")[1]
    }`;
    setDate(yer);
    dispatch(createTableHead(yer));
    dispatch(getReportingHeadAttendance(yer));
  };

  return (
    <div>
      <Wrapper
        title="Reporting Head Attendance"
        Content={
          <Page>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <MarkAbsentees attendances={attendances} />
            </Box>
            <Paper
              elevation={3}
              sx={{ borderRadius: 1, width: "100%", marginTop: 3 }}
            >
              <Box
                sx={{
                  backgroundColor: "grey.200",
                  borderRadius: "8px 8px 0 0",
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Box padding={2}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      views={["year", "month"]}
                      label="Year and Month"
                      inputFormat="YYYY/MM"
                      disableFuture
                      value={value}
                      onChange={handleChange}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          helperText={null}
                          variant="filled"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
              </Box>
              <Box sx={{ width: "100%" }}>
                <DataGrid
                  loading={attendances.loading}
                  rows={attendances.data}
                  columns={tableHead}
                  sx={{
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                  pageSize={gridSize}
                  onPageSizeChange={(newGridSize) => setGridSize(newGridSize)}
                  autoHeight
                  rowsPerPageOptions={[5, 10, 25, 50]}
                  getRowId={(row) => row.userId}
                  components={{ Toolbar: CustomGridToolbar }}
                  componentsProps={{
                    toolbar: {
                      showQuickFilter: true,
                      quickFilterProps: { debounceMs: 500 },
                    },
                  }}
                />
              </Box>
            </Paper>
          </Page>
        }
      />
    </div>
  );
}
