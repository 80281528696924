import React from "react";
import { Icon } from "@iconify/react";
import {
    Box,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
} from "@mui/material";

const Mytable = ({ tableRow }) => {
    return (
        <Grid item xs={12} lg={6} md={6}>
            <Paper
                sx={{
                    margin: 1,
                    borderRadius: 4,
                }}
            >
                <Table>{tableRow}</Table>
            </Paper>
        </Grid>
    );
};

const Rows = ({ label, value }) => {
    return (
        <TableRow>
            <TableCell variant="head" sx={{ padding: 1 }}>
                <Stack direction={"row"} spacing={1}>
                    <Box>
                        <Icon
                            icon="uil:arrow-up"
                            rotate={1}
                            color="#2f72b3"
                            width="25"
                            height="25"
                        />
                    </Box>

                    <Box>{label}</Box>
                </Stack>
            </TableCell>
            <TableCell sx={{ padding: 0.5 }}>{value}</TableCell>
        </TableRow>
    );
};

export const CroLeadsDetails = ({ data }) => {
    return (
        <div>
            <Box marginTop={2}>
                {/* ------------------------------------------------------------------------------ */}

                <Grid container spacing={1}>
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    <Rows
                                        label="Class"
                                        value={data?.className}
                                    />
                                    <Rows
                                        label="Sylabus "
                                        value={data?.syllabusName}
                                    />
                                    <Rows
                                        label="Medium "
                                        value={data?.mediumName}
                                    />
                                    <Rows
                                        label="Course Name"
                                        value={data?.courseName}
                                    />
                                    <Rows
                                        label="Conatct Person "
                                        value={data?.contactPerson}
                                    />
                                    <Rows
                                        label="Conatct Number "
                                        value={data?.contactNumber}
                                    />
                                    <Rows
                                        label="Contact Number"
                                        value={data?.contactNumber}
                                    />
                                    <Rows label="Place" value={data?.place} />
                                    <Rows
                                        label="Lead Remarks"
                                        value={data?.leadRemarks}
                                    />
                                    <Rows
                                        label="Lead CameOn"
                                        value={data?.leadCameOn}
                                    />
                                    <Rows label="OGA" value={data?.oga} />
                                </TableBody>
                            </>
                        }
                    />
                    <Mytable
                        tableRow={
                            <>
                                <TableBody>
                                    <Rows
                                        label="OGA Remarks"
                                        value={data?.ogaRemarks}
                                    />

                                    <Rows
                                        label="OGA Assessment Remarks"
                                        value={data?.ogaAssessmentRemarks}
                                    />

                                    <Rows
                                        label="OGA Admission ConfirmedOn"
                                        value={data?.expectedSessions}
                                    />
                                    <Rows label="JQA" value={data?.jqa} />
                                    <Rows
                                        label="JQA Lead Status"
                                        value={data?.jqaLeadStatusName}
                                    />

                                    <Rows
                                        label="JQA Last Updated On"
                                        value={data?.jqaLastUpdatedOn}
                                    />
                                    <Rows
                                        label="CRO Led Status"
                                        value={data?.croLedStatusName}
                                    />
                                    <Rows
                                        label="CRO Remarks"
                                        value={data?.croRemarks}
                                    />
                                    <Rows
                                        label="CRO Last Updated On"
                                        value={data?.croLastUpdatedOn}
                                    />
                                </TableBody>
                            </>
                        }
                    />
                </Grid>

                {/* ------------------------------------------------------------------------------------------ */}
            </Box>
        </div>
    );
};
