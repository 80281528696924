import Iconify from "../../components/color-utils/Iconify";

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;
const getIconLG = (name) => <Iconify icon={name} width={35} height={35} />;

const navIcons = {
    notifications: getIcon("ci:notification-active"),
    schedules: getIcon("eva:file-text-fill"),
    dashboard: getIcon("eva:pie-chart-2-fill"),
    mytasks: getIcon("cil:task"),
    myworkspace: getIcon("cil:task"),
    myStudents: getIcon("eva:people-fill"),
    todo: getIcon("icons8:todo-list"),
    reffer: getIcon("eva:shopping-bag-fill"),
    employeeServices: getIcon("clarity:employee-solid"),
    privileges: getIcon("icon-park-outline:permissions"),
    masters: getIcon("gala:settings"),
};

const navIconsLG = {
    notifications: getIconLG("ci:notification-active"),
    schedules: getIconLG("eva:file-text-fill"),
    dashboard: getIconLG("eva:pie-chart-2-fill"),
    mytasks: getIconLG("cil:task"),
    myStudents: getIconLG("eva:people-fill"),
    todo: getIconLG("icons8:todo-list"),
    reffer: getIconLG("eva:shopping-bag-fill"),
    myworkspace: getIcon("cil:task"),
    employeeServices: getIconLG("clarity:employee-solid"),
    privileges: getIconLG("icon-park-outline:permissions"),
    masters: getIconLG("gala:settings"),
};

export { navIcons, navIconsLG };
